<template>
  <section class="w-full">
    <page-loader v-if="loading" />
    <div v-if="data && data.length" class="w-full overflow-x-auto">
      <ul class="w-full flex flex-col gap-y-1 md:hidden">
        <li
          v-for="(item, index) in data"
          :key="String(item)"
          :class="`flex justify-between items-center gap-x-3 ${
            index % 2 !== 0 ? 'bg-greyscale-5' : ''
          } ${onRowClick ? 'cursor-pointer' : ''}`"
          @click="() => (onMobileRowClick ? onMobileRowClick(item) : () => {})"
        >
          <div
            v-for="column in columns.filter((column) => column.showOnMobile)"
            :key="String(column)"
            class="text-primary px-3 py-4 text-sm capitalize basis-full max-w-full last:max-w-fit"
          >
            <slot
              :name="`column-${column.dataIndex}`"
              :row="item"
              :column-value="column.selector(item) || ''"
            >
              {{ column.selector(item) || "" }}
            </slot>
          </div>
        </li>
      </ul>
      <div class="w-full hidden md:block">
        <table v-if="!loading" class="table-auto bg-white w-full">
          <thead :class="headerBackground ? 'bg-greyscale-2' : ''">
            <tr class="border-b-[1.5px] border-greyscale-2">
              <th
                v-for="column in columns.filter(
                  (column) => !column.showOnMobile,
                )"
                :key="column.label"
                class="text-text-secondary uppercase text-left px-5 py-3 text-sm font-medium"
              >
                <span>
                  {{ column.label }}
                </span>
                <button v-if="column.sort" class="inline-block pl-2">
                  <up-down-arrow-icon />
                </button>
              </th>
            </tr>
          </thead>
          <tbody v-if="data.length">
            <tr
              v-for="(item, index) in data"
              :key="String(item)"
              :class="`${index % 2 !== 0 ? 'bg-greyscale-5' : ''} ${
                onRowClick ? 'cursor-pointer' : ''
              }`"
              @click="() => (onRowClick ? onRowClick(item) : () => {})"
            >
              <td
                v-for="column in columns.filter(
                  (column) => !column.showOnMobile,
                )"
                :key="String(column)"
                class="text-primary text-left px-5 py-4 text-xs 2xl:text-sm max-w-[180px] capitalize"
              >
                <slot
                  :name="`column-${column.dataIndex}`"
                  :row="item"
                  :column-value="column.selector(item) || ''"
                >
                  {{ column.selector(item) || "" }}
                </slot>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      v-if="!loading && data && !data.length"
      class="w-full h-[365px] flex flex-col justify-center items-center"
    >
      <slot v-if="!emptyMessage" name="empty-state" />
      <div v-else class="w-full flex flex-col justify-center items-center">
        <empty-icon />
        <p
          class="text-text-secondary font-medium text-base mt-1 max-w-sm text-center"
        >
          {{ emptyMessage }}
        </p>
      </div>
    </div>
    <app-pagination
      v-if="data && data.length && pagination"
      :key="componentKey"
      :total-items="pagination.totalItems"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      @change="handleChange"
    />
  </section>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import {
  PaginateChangeParams,
  PaginateProps,
  TableChangeParams,
  TableColumn,
} from "./table.props";
import { watch } from "vue";

interface iProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  columns: TableColumn<any>[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any[];
  emptyMessage?: string;
  pagination?: PaginateProps;
  loading?: boolean;
  headerBackground?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRowClick?: (row: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onMobileRowClick?: (row: any) => void;
}

const props = defineProps<iProps>();

interface ChangeParams extends TableChangeParams {}

const emit = defineEmits<{
  (e: "change", params: ChangeParams): void;
}>();

const componentKey = ref(0);

const handleChange = (params: PaginateChangeParams) => {
  emit("change", {
    perPage: params.perPage,
    currentPage: params.currentPage,
    sort: {},
  });
};

const forcePaginationRerender = () => {
  componentKey.value += 1;
};

watch(
  () => props.data,
  () => {
    forcePaginationRerender();
  },
);
</script>
