<template>
  <div class="w-full px-5 py-6">
    <form class="w-full" @submit="onSubmit">
      <div
        v-if="currentView === 'bank-details'"
        class="w-full flex flex-col gap-y-4"
      >
        <app-input
          v-for="it in selectedPaymentMethodRequirement.filter(
            (a) => a.key !== 'bank_address' && a.key !== 'intermediary_bank',
          )"
          :key="it.key"
          v-bind="formFields[it.key]"
          :error-message="errors[it.key]"
          :type="it.allowed_values ? 'select' : 'text'"
          :label="it.description"
          :name="it.key"
          :required="it.required"
          :show-required-label="it.required"
          :placeholder="`Enter ${it.description}`"
        >
          <option v-if="it.allowed_values" value="" selected disabled>
            Select {{ it.description }}
          </option>
          <option
            v-for="val in it.allowed_values || []"
            :key="val"
            :value="val"
          >
            {{ val.toLocaleUpperCase() }}
          </option>
        </app-input>

        <label
          v-if="formData.method.type === 'SWIFT'"
          class="text-sm leading-[0px] text-primary flex items-center gap-x-2"
          :class="{ 'mb-4': !showIntermediaryBankDetails }"
        >
          <input
            v-model="showIntermediaryBankDetails"
            class="w-3 h-3"
            type="checkbox"
          />
          Have intermediary bank details?
        </label>

        <template v-if="showIntermediaryBankDetails">
          <app-input
            v-bind="formFields.intermediary_bank_name"
            :error-message="errors.intermediary_bank_name"
            label="Intermediary Bank Name"
            type="text"
            name="intermediary_bank_name"
            placeholder="Enter intermediary bank name"
            required
            show-required-label
          />

          <app-input
            v-bind="formFields.intermediary_swift_bic"
            :error-message="errors.intermediary_swift_bic"
            label="Intermediary Bank SWIFT/BIC"
            type="text"
            name="intermediary_swift_bic"
            placeholder="Enter intermediary bank SWIFT/BIC"
            required
            show-required-label
          />
        </template>
      </div>

      <div v-else class="w-full flex flex-col gap-y-4 mb-4">
        <app-input
          v-bind="formFields.intermediary_line_1"
          :error-message="errors.intermediary_line_1"
          label="Intermediary Bank Address Line 1"
          type="text"
          name="intermediary_line_1"
          placeholder="Enter intermediary bank address line 1"
          required
          show-required-label
        />
        <app-input
          v-bind="formFields.intermediary_line_2"
          :error-message="errors.intermediary_line_2"
          label="Intermediary Bank Address Line 2"
          type="text"
          name="intermediary_line_2"
          placeholder="Enter intermediary bank address line 2"
        />
        <app-input
          v-bind="formFields.intermediary_city"
          :error-message="errors.intermediary_city"
          label="Intermediary Bank City"
          type="text"
          name="intermediary_city"
          placeholder="Enter intermediary bank city"
          required
          show-required-label
        />

        <app-input
          v-bind="formFields.intermediary_state"
          :error-message="errors.intermediary_state"
          label="Intermediary Bank State"
          type="text"
          name="intermediary_state"
          placeholder="Enter intermediary bank state"
          required
          show-required-label
        />

        <app-input
          v-bind="formFields.intermediary_postal_code"
          :error-message="errors.intermediary_postal_code"
          label="Intermediary Bank Postal Code"
          type="text"
          name="intermediary_postal_code"
          placeholder="Enter intermediary bank postal code"
          required
          show-required-label
        />

        <combo-input
          label="Intermediary Bank Country"
          :data="countries"
          :default-value="values.intermediary_country"
          :error-message="errors.intermediary_country"
          required
          show-required-label
          @select="handleSelectedIntermediaryCountry"
        />
      </div>

      <div
        v-if="currentView === 'bank-details'"
        class="flex justify-between gap-x-3 mt-4"
      >
        <app-button
          type="button"
          size="lg"
          variant="outlined"
          @click="switchForm('bank-address')"
          >Back</app-button
        >
        <app-button
          v-if="showIntermediaryBankDetails"
          type="button"
          size="lg"
          variant="primary"
          @click="switchView('intermediary-details')"
          >Next</app-button
        >
        <app-button
          v-else
          :loading="submitting"
          :disabled="submitting"
          type="submit"
          size="lg"
          variant="primary"
          >Create</app-button
        >
      </div>

      <div v-else class="flex justify-between gap-x-3 mt-4">
        <app-button
          type="button"
          size="lg"
          variant="outlined"
          @click="switchView('bank-details')"
          >Back</app-button
        >
        <app-button
          :loading="submitting"
          :disabled="submitting"
          type="submit"
          size="lg"
          variant="primary"
          >Create</app-button
        >
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { useForm } from "vee-validate";
import { reactive, watch } from "vue";
import * as yup from "yup";
import { CreateBankingBeneficiary, BankingBeneficiaryView } from "./type";
import { SupportedPaymentMethodsData } from "@/types";
import { computed, ref } from "vue";
import { countriesList } from "@/helpers/countries";
import { SelectInputData } from "@/components/shared/type";

const props = defineProps<{
  switchForm: (view: BankingBeneficiaryView) => void;
  handleCreate: (data: CreateBankingBeneficiary) => void;
  submitting: boolean;
  formData: CreateBankingBeneficiary;
  updateFormData: (data: CreateBankingBeneficiary) => void;
  supportedPaymentMethods: SupportedPaymentMethodsData[];
}>();

type TFormView = "bank-details" | "intermediary-details";

const showIntermediaryBankDetails = ref(false);
const currentView = ref<TFormView>("bank-details");

const switchView = (view: TFormView) => {
  currentView.value = view;
};

type FormFields = Record<string, string> & {
  intermediary_bank_name?: string;
  intermediary_swift_bic?: string;
  intermediary_line_1?: string;
  intermediary_line_2?: string;
  intermediary_city?: string;
  intermediary_postal_code?: string;
  intermediary_country?: string;
  intermediary_state?: string;
};

const countries = computed(() => {
  return countriesList.map((it) => ({
    label: it.name,
    value: it.code,
  }));
});

const selectedPaymentMethodRequirement = computed(() => {
  const method = props.supportedPaymentMethods.find(
    (it) => it.type === props.formData.method.type,
  );
  if (method) {
    return method.requirements;
  }
  return [];
});

const { errors, handleSubmit, defineInputBinds, values, setFieldValue } =
  useForm<FormFields>({
    validationSchema: yup.object({
      ...selectedPaymentMethodRequirement.value
        .filter(
          (a) => a.key !== "bank_address" && a.key !== "intermediary_bank",
        )
        .reduce(
          (obj, it) => {
            return {
              ...obj,
              [it.key]: it.required
                ? yup.string().required().label(it.description)
                : yup.string().label(it.description),
            };
          },
          {} as Record<string, yup.StringSchema>,
        ),
      intermediary_bank_name: yup
        .string()
        .label("Intermediary Bank Name")
        .optional(),
      intermediary_swift_bic: yup
        .string()
        .label("Intermediary Bank SWIFT/BIC")
        .optional(),
      intermediary_line_1: yup
        .string()
        .label("Intermediary Bank Address Line 1")
        .optional(),
      intermediary_line_2: yup
        .string()
        .label("Intermediary Bank Address Line 2")
        .optional(),
      intermediary_city: yup
        .string()
        .label("Intermediary Bank City")
        .optional(),
      intermediary_postal_code: yup
        .string()
        .label("Intermediary Bank Postal Code")
        .optional(),
      intermediary_country: yup
        .string()
        .label("Intermediary Bank Country")
        .optional(),
      intermediary_state: yup
        .string()
        .label("Intermediary Bank State")
        .optional(),
    }),

    initialValues: {
      ...selectedPaymentMethodRequirement.value
        .filter((a) => a.key !== "bank_address")
        .reduce(
          (obj, it) => {
            return {
              ...obj,
              [it.key]: props.formData.method.details[it.key] || undefined,
            };
          },
          {} as Record<string, string>,
        ),
      intermediary_bank_name:
        props.formData.method.details.intermediary_bank?.name,
      intermediary_swift_bic:
        props.formData.method.details.intermediary_bank?.swift_bic,
      intermediary_line_1:
        props.formData.method.details.intermediary_bank?.address?.line_1,
      intermediary_line_2:
        props.formData.method.details.intermediary_bank?.address?.line_2,
      intermediary_city:
        props.formData.method.details.intermediary_bank?.address?.city,
      intermediary_postal_code:
        props.formData.method.details.intermediary_bank?.address?.postal_code,
      intermediary_country:
        props.formData.method.details.intermediary_bank?.address?.country,
      intermediary_state:
        props.formData.method.details.intermediary_bank?.address?.state,
    },
  });

const handleSelectedIntermediaryCountry = (val: SelectInputData) => {
  setFieldValue("intermediary_country", val.value);
};

watch(showIntermediaryBankDetails, (newVal) => {
  if (!newVal) {
    props.updateFormData({
      ...props.formData,
      method: {
        type: props.formData.method.type,
        details: {
          ...props.formData.method.details,
          intermediary_bank: undefined,
        },
      },
    });
  }
});

watch(values, (newValues) => {
  const withoutIntermediaryDetails = Object.fromEntries(
    Object.entries(newValues).filter(
      ([key]) => !key.startsWith("intermediary_"),
    ),
  );

  const payload: CreateBankingBeneficiary = {
    ...props.formData,
    method: {
      type: props.formData.method.type,
      details: {
        ...props.formData.method.details,
        ...withoutIntermediaryDetails,
      },
    },
  };

  if (showIntermediaryBankDetails.value) {
    payload.method.details = {
      ...payload.method.details,
      intermediary_bank: {
        name: newValues.intermediary_bank_name,
        swift_bic: newValues.intermediary_swift_bic,
        address: {
          line_1: newValues.intermediary_line_1,
          line_2: newValues.intermediary_line_2,
          city: newValues.intermediary_city,
          postal_code: newValues.intermediary_postal_code,
          country: newValues.intermediary_country,
          state: newValues.intermediary_state,
        },
      },
    };
  }
  props.updateFormData(payload);
});

const onSubmit = handleSubmit(() => {
  props.handleCreate(props.formData);
});

const formFieldsInputBinds = computed(() => {
  return selectedPaymentMethodRequirement.value
    .filter((a) => a.key !== "bank_address")
    .reduce(
      (obj, it) => {
        return {
          ...obj,
          [it.key]: defineInputBinds(it.key),
        };
      },
      {} as Record<keyof FormFields, ReturnType<typeof defineInputBinds>>,
    );
});

const formFields = reactive<
  Record<keyof FormFields, ReturnType<typeof defineInputBinds>>
>({
  ...formFieldsInputBinds.value,
  intermediary_bank_name: defineInputBinds("intermediary_bank_name"),
  intermediary_swift_bic: defineInputBinds("intermediary_swift_bic"),
  intermediary_line_1: defineInputBinds("intermediary_line_1"),
  intermediary_line_2: defineInputBinds("intermediary_line_2"),
  intermediary_city: defineInputBinds("intermediary_city"),
  intermediary_postal_code: defineInputBinds("intermediary_postal_code"),
  intermediary_country: defineInputBinds("intermediary_country"),
  intermediary_state: defineInputBinds("intermediary_state"),
});
</script>
