<template>
  <div class="w-full px-5 pt-4 pb-6">
    <h3 class="font-semibold text-sm mb-4">Bank Address</h3>

    <form class="w-full mt-4 flex flex-col gap-y-4" @submit="onSubmit">
      <app-input
        type="text"
        v-bind="formFields.line_1"
        :error-message="errors.line_1"
        name="line_1"
        label="Address Line 1"
        placeholder="Enter address line 1"
        required
        show-required-label
      />
      <app-input
        type="text"
        v-bind="formFields.line_2"
        :error-message="errors.line_2"
        name="line_2"
        label="Address Line 2"
        placeholder="Enter address line 2"
      />
      <app-input
        type="text"
        v-bind="formFields.city"
        :error-message="errors.city"
        name="city"
        label="City"
        placeholder="Enter City"
        required
        show-required-label
      />
      <app-input
        type="text"
        v-bind="formFields.state"
        :error-message="errors.state"
        name="state"
        label="State"
        placeholder="Enter State"
        required
        show-required-label
      />
      <app-input
        type="text"
        v-bind="formFields.postal_code"
        :error-message="errors.postal_code"
        name="postal_code"
        label="Postal Code"
        placeholder="Enter postal code"
        required
        show-required-label
      />
      <app-input
        v-if="selectedCountry"
        type="text"
        name="country"
        :model-value="selectedCountry"
        label="Country"
        placeholder="Enter country"
        required
        disabled
      />
      <div class="flex justify-between gap-x-3">
        <app-button
          type="button"
          size="lg"
          variant="outlined"
          @click="switchForm('beneficiary-address')"
          >Back</app-button
        >
        <app-button type="submit" size="lg" variant="primary"
          >Continue</app-button
        >
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { countryOf } from "@/helpers/countries";
import { CreateBankingBeneficiary, BankingBeneficiaryView } from "./type";
import { useForm } from "vee-validate";
import { reactive, ref } from "vue";
import * as yup from "yup";

const props = defineProps<{
  switchForm: (view: BankingBeneficiaryView) => void;
  updateFormData: (data: CreateBankingBeneficiary) => void;
  formData: CreateBankingBeneficiary;
}>();

interface FormFields {
  line_1: string;
  line_2?: string;
  city: string;
  state: string;
  postal_code: string;
}

const selectedCountry = ref(countryOf(props.formData.country).name);

const { errors, handleSubmit, defineInputBinds } = useForm<FormFields>({
  validationSchema: yup.object({
    line_1: yup.string().required().label("Line 1"),
    line_2: yup.string().optional().label("Line 2"),
    city: yup.string().required().label("City"),
    state: yup.string().required().label("State"),
    postal_code: yup.string().required().label("Postal code"),
  }),
  initialValues: {
    line_1: props.formData.method.details.bank_address?.line_1,
    line_2: props.formData.method.details.bank_address?.line_2,
    city: props.formData.method.details.bank_address?.city,
    state: props.formData.method.details.bank_address?.state,
    postal_code: props.formData.method.details.bank_address?.postal_code,
  },
});

const onSubmit = handleSubmit((values) => {
  props.updateFormData({
    ...props.formData,
    method: {
      type: props.formData.method.type,
      details: {
        ...props.formData.method.details,
        bank_address: {
          line_1: values.line_1,
          line_2: values.line_2,
          city: values.city,
          state: values.state,
          postal_code: values.postal_code,
          country: props.formData.country,
        },
      },
    },
  });
  props.switchForm("beneficiary-bank");
});

const formFields = reactive({
  line_1: defineInputBinds("line_1"),
  line_2: defineInputBinds("line_2"),
  city: defineInputBinds("city"),
  state: defineInputBinds("state"),
  postal_code: defineInputBinds("postal_code"),
});
</script>
