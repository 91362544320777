<template>
  <div class="w-full bg-white rounded-[10px] min-h-[300px]">
    <deal-result
      v-if="showResult"
      :deal-id="deal.id"
      :handle-close="closeModal"
    />
    <not-eligible-deal v-else-if="showNotEligible" :handle-close="closeModal" />
    <deal-details
      v-else
      :id="deal.id"
      :name="deal.name"
      :state="deal.business_deal_state"
      :avatar="deal.logo_url"
      :description="deal.description"
      :rewards="deal.benefits"
      :eligibility-questions="
        deal.eligbility_questions.map((it) => ({
          id: it.id,
          content: it.content.en,
        }))
      "
      :category="deal.category"
      :redeem-deal="handleRedeemDeal"
      :loading="submitting"
      :close-deal-details="closeModal"
    />
  </div>
</template>

<script setup lang="ts">
import { useAppToast } from "@/composables";
import { useWriteResource } from "@/composables/use-resource";
import { errorMessage } from "@/helpers/error";
import { BusinessDealsResponse, RedeemDealRequest } from "@/types";
import { onMounted, ref } from "vue";

const props = defineProps<{
  deal: BusinessDealsResponse;
  closeModal: () => void;
}>();

const toast = useAppToast();
const showResult = ref(false);
const showNotEligible = ref(false);

const { submitting, execute } = useWriteResource(
  `deals/${props.deal.id}/redeem`,
  "post",
  {
    successTitle: "Your request has been submitted",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (err: any) => {
      //TODO: Find a better way to handle this
      if (
        err.response?.data?.error?.message ===
        "You do not qualify for this deal"
      ) {
        showNotEligible.value = true;
      }
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess(value: any) {
      if (value.redemption_instruction) {
        showResult.value = true;
      } else {
        props.closeModal();
      }
    },
  },
);

const handleRedeemDeal = async (request: RedeemDealRequest) => {
  execute({
    body: request,
  });
};

onMounted(() => {
  if (
    props.deal.business_deal_state &&
    props.deal.business_deal_state === "REDEEMED"
  ) {
    showResult.value = true;
  }
});
</script>
