<template>
  <section
    class="relative w-full xl:w-[35%] border border-greyscale-1 px-6 py-4 rounded-[10px]"
  >
    <div class="flex justify-between items-center">
      <h2 class="text-primary text-base font-[800]">Swap your assets</h2>
      <button
        class="text-sm text-primary flex items-center"
        @click="toggleCurrentFxRates"
      >
        <span>See rates </span>
        <arrow-right-icon />
      </button>
    </div>
    <div class="w-full rounded-[6px] border border-greyscale-3 py-6 px-5 my-4">
      <div class="w-full flex justify-between items-start">
        <div class="w-[45%]">
          <swap-input v-model="sourceAmount" label="You give" />
        </div>
        <div class="w-[45%] flex flex-col items-end">
          <div class="text-text-primary text-xs w-full mb-3 text-right">
            Bal. {{ sourceCurrencyBalance }}
          </div>
          <select-swap-currency
            v-model="sourceCurrency"
            :currencies="
              accountsList.map((it) => ({
                currency: it.currency,
                blockchain: it.blockchain,
              })) || []
            "
          />
        </div>
      </div>
      <div class="w-full flex justify-between items-center my-3">
        <div class="h-[1px] bg-greyscale-1 w-[45%]"></div>
        <swap-icon />
        <div class="h-[1px] bg-greyscale-1 w-[45%]"></div>
      </div>
      <div class="w-full flex justify-between items-start">
        <div class="w-[45%]">
          <swap-input
            v-model="destinationAmount"
            label="You receive"
            disabled
          />
        </div>
        <div class="w-[45%] flex flex-col items-end">
          <select-swap-currency
            v-model="destinationCurrency"
            :currencies="
              possibleDestinationCurrencies.map((it) => ({
                currency: it.currency,
                blockchain: it.blockchain,
              })) || []
            "
          />
        </div>
      </div>
    </div>
    <div class="my-4 text-sm text-text-primary font-[800]">
      <p v-if="exchangeRate && sourceCurrency?.currency">
        <rate-description
          :rate="exchangeRate"
          :sending-currency="sourceCurrency?.currency"
        />
      </p>

      <p v-if="quote">
        Fee {{ currencyOf(quote.feeCurrency).symbol }} {{ quote.feeAmount }}
      </p>
    </div>
    <app-button
      bold
      variant="primary"
      size="lg"
      :loading="fetchingBankingQuote || fetchingCryptoQuote"
      :disabled="fetchingCryptoQuote || fetchingBankingQuote || !quote"
      @click="handleShowConfirmation"
      >Swap</app-button
    >

    <app-modal
      v-if="successfulTxn"
      :is-open="showTxnStatus"
      :handle-close="() => {}"
      size="lg"
    >
      <swap-txn-success :transaction="successfulTxn" />
    </app-modal>

    <app-modal
      v-if="quote"
      :is-open="showConfirmation"
      :handle-close="() => {}"
      size="lg"
    >
      <swap-confirmation
        :cancel-payment="handleCancelPayment"
        :make-payment="handlePayment"
        :quote="quote"
        :loading="loading"
      >
        <template v-if="isOTPRequired && OTPComponent" #confirm-button>
          <component :is="OTPComponent" />
        </template>
      </swap-confirmation>
    </app-modal>
  </section>
  <app-modal
    :is-open="showCurrentFxRates"
    :handle-close="toggleCurrentFxRates"
    size="lg"
  >
    <current-fx-rates :close-modal="toggleCurrentFxRates" />
  </app-modal>
</template>

<script lang="ts" setup>
import {
  formatAmount,
  formatAmountToMajor,
  LYNC_OTP_CODE_HEADER,
} from "@/helpers";
import { computed, ref, watch } from "vue";
import { useWriteResource } from "@/composables/use-resource";
import { bankingUrl, cryptoUrl } from "@/helpers/apiClient";
import { errorMessage, isOtpError } from "@/helpers/error";
import { useAppToast } from "@/composables";
import {
  MergedAccount,
  SwapCurrency,
  SwapSuccessfulTxn,
  SwapTransactionQuote,
} from "./types";
import { useQueryClient } from "@tanstack/vue-query";
import { CurrentRateV2, QueryKeys } from "@/types";
import { currencyOf } from "@/helpers/currencies";
import { debounce } from "lodash";
import { useOtpVerification } from "@/composables/use-otp-verification";

const props = defineProps<{
  accountsList: MergedAccount[];
}>();

const toast = useAppToast();

const sourceCurrency = ref<SwapCurrency | null>(null);
const destinationCurrency = ref<SwapCurrency | null>(null);
const sourceAmount = ref<number>();
const destinationAmount = ref<number>();
const quote = ref<SwapTransactionQuote>();
const exchangeRate = ref<CurrentRateV2>();
const successfulTxn = ref<SwapSuccessfulTxn>();
const showConfirmation = ref(false);
const showTxnStatus = ref(false);
const showCurrentFxRates = ref(false);
const loading = ref(false);

const toggleCurrentFxRates = () => {
  showCurrentFxRates.value = !showCurrentFxRates.value;
};

const txnError = ref<string>();
const queryClient = useQueryClient();

const {
  isOTPRequired,
  execute: withOtp,
  component: OTPComponent,
  reset,
} = useOtpVerification({
  service: sourceCurrency.value?.blockchain ? "crypto" : "fiat",
  action: "EXCHANGE",
  format: "component",
});

const possibleDestinationCurrencies = ref<
  {
    currency: string;
    blockchain?: string;
  }[]
>([]);

const sourceCurrencyBalance = computed(() => {
  const source = props.accountsList.find(
    (it) =>
      it.currency === sourceCurrency.value?.currency &&
      it.blockchain === sourceCurrency.value.blockchain,
  );
  return source?.balance || "0.0";
});

const { submitting: fetchingBankingQuote, execute: getBankingQuote } =
  useWriteResource(bankingUrl("quotes/exchange"), "post", {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  });

const { submitting: fetchingCryptoQuote, execute: getCryptoQuote } =
  useWriteResource(cryptoUrl("quotes/exchange"), "post", {
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  });

const { execute: makeCryptoPayment } = useWriteResource(
  cryptoUrl("transactions/exchange"),
  "post",
  {
    successTitle: "Your exchange is being processed",
    onSuccess: () => {
      showTxnStatus.value = true;
      showConfirmation.value = false;
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ASSETS],
      });
    },
    onError: (err) => {
      isOtpError(err);
      quote.value = undefined;
      txnError.value = errorMessage(err);
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { execute: makeBankingPayment } = useWriteResource(
  bankingUrl("payments/exchange"),
  "post",
  {
    successTitle: "Your exchange is being processed",
    onSuccess: () => {
      showTxnStatus.value = true;
      showConfirmation.value = false;
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.ACCOUNTS],
      });
    },
    onError: (err) => {
      isOtpError(err);
      quote.value = undefined;
      txnError.value = errorMessage(err);
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const handleCancelPayment = () => {
  quote.value = undefined;
  sourceAmount.value = undefined;
  exchangeRate.value = undefined;
  destinationAmount.value = undefined;
  showConfirmation.value = false;
  reset();
};

const handleShowConfirmation = () => {
  if (quote.value) {
    showConfirmation.value = true;
  }
};

const handleGetCryptoQuote = async () => {
  const source = props.accountsList.find(
    (it) =>
      it.currency === sourceCurrency.value?.currency &&
      it.blockchain === sourceCurrency.value.blockchain,
  );

  if (source && sourceAmount.value) {
    const res = await getCryptoQuote({
      body: {
        asset_id: source.id,
        amount_in_major: sourceAmount.value.toString(),
        destination_currency: destinationCurrency.value?.currency,
      },
    });

    quote.value = {
      sourceAmount: formatAmount(res.source_amount.amount),
      sourceCurrency: res.source_amount.currency,
      destinationAmount: formatAmount(res.destination_amount.amount),
      destinationCurrency: res.destination_amount.currency,
      feeAmount: formatAmount(res.fee.amount || 0),
      feeCurrency: res.fee.currency,
      exchangeRate: res.exchange.rate,
      exchange: res.exchange,
    };
    destinationAmount.value = Number(res.destination_amount.amount);
    exchangeRate.value = res.exchange;
  }
};

const handleGetExchangeRate = async () => {
  if (
    sourceCurrency.value &&
    sourceCurrency.value.blockchain &&
    destinationCurrency.value
  ) {
    const source = props.accountsList.find(
      (it) =>
        it.currency === sourceCurrency.value?.currency &&
        it.blockchain === sourceCurrency.value.blockchain,
    );

    const res = await getCryptoQuote({
      body: {
        asset_id: source?.id,
        amount_in_major: "10000",
        destination_currency: destinationCurrency.value?.currency,
      },
    });
    exchangeRate.value = res.exchange;
  } else if (sourceCurrency.value && destinationCurrency.value) {
    const source = props.accountsList.find(
      (it) => it.currency === sourceCurrency.value?.currency,
    );

    const res = await getBankingQuote({
      body: {
        source_account_id: source?.id,
        fixed_side: "source",
        amount: 10000,
        destination_currency: destinationCurrency.value?.currency,
      },
    });

    exchangeRate.value = res.fx_rate;
  }
};

const handleGetBankingQuote = async () => {
  const source = props.accountsList.find(
    (it) => it.currency === sourceCurrency.value?.currency,
  );

  if (source && sourceAmount.value) {
    const amount = Math.round(
      Number(sourceAmount.value) * 10 ** currencyOf(source.currency).precision,
    );

    const res = await getBankingQuote({
      body: {
        source_account_id: source.id,
        fixed_side: "source",
        amount,
        destination_currency: destinationCurrency.value?.currency,
      },
    });

    quote.value = {
      sourceAmount: formatAmountToMajor(
        res.sending_amount.amount,
        res.sending_amount.currency,
      ),
      sourceCurrency: res.sending_amount.currency,
      destinationAmount: formatAmountToMajor(
        res.destination_amount.amount,
        res.destination_amount.currency,
      ),
      destinationCurrency: res.destination_amount.currency,
      feeAmount: formatAmountToMajor(
        res.total_fees.amount,
        res.total_fees.currency,
      ),
      feeCurrency: res.total_fees.currency,
      exchangeRate: res.exchange_rate,
      exchange: null,
    };
    destinationAmount.value =
      Number(res.destination_amount.amount) /
      10 ** currencyOf(res.destination_amount.currency).precision;
    exchangeRate.value = res.fx_rate;
  }
};

const handleCryptoPayment = async () => {
  const source = props.accountsList.find(
    (it) =>
      it.currency === sourceCurrency.value?.currency &&
      it.blockchain === sourceCurrency.value.blockchain,
  );

  if (quote.value && source && sourceAmount.value) {
    const body = {
      asset_id: source.id,
      amount_in_major: sourceAmount.value.toString(),
      destination_currency: destinationCurrency.value?.currency,
      blockchain: destinationCurrency.value?.blockchain,
    };

    try {
      loading.value = true;
      await withOtp({
        body,
        service: "crypto",
        callback: async (otp?: string) => {
          const txn = await makeCryptoPayment({
            body,
            headers: otp ? { [LYNC_OTP_CODE_HEADER]: otp } : undefined,
          });
          reset();
          loading.value = false;
          quote.value = undefined;
          successfulTxn.value = {
            sourceAmount: formatAmount(txn.source_amount.value),
            sourceCurrency: txn.source_amount.currency,
            destinationAmount: formatAmount(txn.destination_amount.value),
            destinationCurrency: txn.destination_amount.currency,
            feeAmount: formatAmount(txn.fee.value),
            feeCurrency: txn.fee.currency,
            description: txn.description,
            state: txn.state,
          };
        },
      });
    } catch (err) {
      loading.value = false;
      toast.error("Error initiating payment - Contact support", {
        position: "top-right",
      });
    }
  }
};

const handleBankingPayment = async () => {
  const source = props.accountsList.find(
    (it) => it.currency === sourceCurrency.value?.currency,
  );

  if (quote.value && source && sourceAmount.value) {
    const amount = Math.round(
      Number(sourceAmount.value) * 10 ** currencyOf(source.currency).precision,
    );

    const body = {
      source_account_id: source.id,
      amount,
      fixed_side: "source",
      destination_currency: destinationCurrency.value?.currency,
      blockchain: destinationCurrency.value?.blockchain,
    };

    try {
      loading.value = true;
      await withOtp({
        body,
        service: "fiat",
        callback: async (otp?: string) => {
          const txn = await makeBankingPayment({
            body,
            headers: otp ? { [LYNC_OTP_CODE_HEADER]: otp } : undefined,
          });
          reset();
          loading.value = false;
          quote.value = undefined;
          successfulTxn.value = {
            sourceAmount: formatAmountToMajor(txn.source_amount, txn.currency),
            sourceCurrency: txn.currency,
            destinationAmount: formatAmountToMajor(
              txn.destination_amount,
              txn.destination_currency,
            ),
            destinationCurrency: txn.destination_currency,
            feeAmount: formatAmountToMajor(txn.total_fee_amount, txn.currency),
            feeCurrency: txn.currency,
            description: txn.description,
            state: txn.state,
          };
        },
      });
    } catch (err) {
      loading.value = false;
      toast.error("Error initiating payment - Contact support", {
        position: "top-right",
      });
    }
  }
};

const handleGetQuote = () => {
  if (sourceCurrency.value && sourceCurrency.value.blockchain) {
    handleGetCryptoQuote();
  } else if (sourceCurrency.value) {
    handleGetBankingQuote();
  }
};

const handlePayment = () => {
  if (sourceCurrency.value && sourceCurrency.value.blockchain) {
    handleCryptoPayment();
  } else if (sourceCurrency.value) {
    handleBankingPayment();
  }
};

watch(sourceCurrency, (val) => {
  if (val) {
    const destination = props.accountsList.find(
      (it) => it.currency === val.currency && it.blockchain === val.blockchain,
    );
    possibleDestinationCurrencies.value = destination?.supportedExchanges || [];
  }

  return [];
});

watch(
  sourceAmount,
  debounce(() => {
    if (sourceAmount.value) {
      handleGetQuote();
    } else {
      quote.value = undefined;
    }
  }, 1000),
);

watch(destinationCurrency, (val) => {
  exchangeRate.value = undefined;
  quote.value = undefined;

  if (val) {
    handleGetExchangeRate();
    handleGetQuote();
  }
});

watch(sourceCurrency, (val) => {
  destinationCurrency.value = null;
  exchangeRate.value = undefined;
  quote.value = undefined;

  if (val) {
    handleGetExchangeRate();

    handleGetQuote();
  }
});
</script>
