<template>
  <div class="w-full mt-10">
    <app-table
      v-if="assets || bankingAccounts"
      :data="accountList"
      :columns="columns"
      :loading="isLoading || loadingBanking"
      :on-row-click="handleRowClicked"
      :on-mobile-row-click="handleRowClicked"
      @change="handleTableChange"
    >
      <template #column-account="props">
        <div v-if="props.row.crypto_account" class="max-w-fit">
          <div class="flex items-center gap-x-1">
            <asset-type :asset="props.row.crypto_account.asset?.currency" />
            <div>({{ props.row.crypto_account.asset.blockchain }})</div>
          </div>
          <div class="ml-6 text-xs text-text-secondary">
            {{
              props.row.crypto_account.deposit_address
                ? maskAddress(props.row.crypto_account.deposit_address?.address)
                : "N/A"
            }}
          </div>
        </div>
        <div v-else-if="props.row.banking_account" class="max-w-fit">
          <div>
            <asset-type :asset="props.row.banking_account.currency" />
          </div>
        </div>
      </template>

      <template #column-balance="props">
        <div v-if="props.row.crypto_account">
          {{
            printMoney(
              formatAmount(
                props.row.crypto_account.balance,
                props.row.crypto_account.precision,
              ),
              props.row.crypto_account.asset.currency,
            )
          }}
        </div>
        <div v-else-if="props.row.banking_account.balance">
          {{
            printMoney(
              formatAmountToMajor(
                props.row.banking_account.balance.available,
                props.row.banking_account.currency,
              ),
              props.row.banking_account.currency,
            )
          }}
        </div>
        <div v-else>
          {{
            printMoney(
              formatAmountToMajor("0.00", props.row.banking_account.currency),
              props.row.banking_account.currency,
            )
          }}
        </div>
      </template>

      <template #column-bank="props">
        <div class="text-left">
          <div v-if="props.row.crypto_account">
            {{ props.row.crypto_account.asset.blockchain }}
          </div>
          <div
            v-else-if="
              props.row.banking_account &&
              !props.row.banking_account.in_request_state
            "
          >
            <template v-if="props.row.banking_account.currency === 'NGN'">
              <span
                v-for="(it, index) in props.row.banking_account
                  .deposit_addresses"
                :key="index"
              >
                {{ it.data.bankName || it.data.bank_name }}
                <span
                  v-if="
                    index !==
                    props.row.banking_account.deposit_addresses.length - 1
                  "
                  >/
                </span>
              </span>
            </template>
            <template v-else>
              <span>
                {{
                  props.row.banking_account.deposit_addresses[0].data
                    .bankName ||
                  props.row.banking_account.deposit_addresses[0].data.bank_name
                }}
              </span>
            </template>
          </div>
          <div v-else>N/A</div>
        </div>
      </template>

      <template #column-status="props">
        <div v-if="props.row.banking_account?.in_request_state">
          <transaction-status
            :status="
              props.row.banking_account.state === 'READY'
                ? 'PENDING'
                : props.row.banking_account.state
            "
          />
        </div>
      </template>

      <template #column-action="props">
        <router-link
          v-if="props.row.crypto_account"
          :to="`/accounts/crypto/${props.row.crypto_account.id}`"
        >
          <circle-arrow-right-icon />
        </router-link>
        <router-link
          v-else-if="
            props.row.banking_account &&
            !props.row.banking_account.in_request_state
          "
          :to="`/accounts/fiat/${props.row.banking_account.id}`"
        >
          <circle-arrow-right-icon />
        </router-link>
        <app-button
          v-else-if="
            props.row.banking_account &&
            props.row.banking_account.in_request_state &&
            props.row.banking_account.state !== 'CANCELLED' &&
            !props.row.banking_account.has_completed_tasks &&
            hasPermission(LyncPermissions.accounts_write)
          "
          variant="primary"
          size="sm"
          @click="
            openAccountRequest(
              props.row.banking_account.account_request_id || '',
            )
          "
        >
          Complete <span class="hidden lg:inline-block">tasks</span>
        </app-button>
      </template>
      <template #empty-state>
        <div
          class="w-full max-w-[300px] mx-auto flex flex-col gap-y-3 justify-center items-center"
        >
          <empty-data />
          <h4 class="font-bold text-base lg:text-lg">
            You&apos;ve not created any account yet
          </h4>
          <p class="text-base text-center text-text-primary">
            Create a bank account or a digital asset wallet to get started
          </p>
        </div>
      </template>
    </app-table>
    <error-component
      v-else-if="isError || bankingError"
      message="Error fetching accounts"
    />
  </div>
  <app-modal
    v-if="activeAccountRequestId"
    :is-open="isAccountRequestOpen"
    :handle-close="closeAccountRequest"
    size="xl"
    align-right
  >
    <account-request-modal
      :close-modal="closeAccountRequest"
      :request-id="activeAccountRequestId"
    />
  </app-modal>
</template>

<script lang="ts" setup>
import { useFeature } from "@/composables/use-feature";
import { TableColumn } from "../shared/table/table.props";
import { useGetAssets } from "@/data-access/assets";
import {
  formatAmount,
  formatAmountToMajor,
  maskAddress,
  printMoney,
} from "@/helpers";
import { useRouter } from "vue-router";
import { useListCorporateAccounts } from "@/data-access/accounts";
import { computed, ref } from "vue";
import { IAccount } from "@/types";
import { LyncPermissions } from "@/security/permissions";
import { usePermission } from "@/composables/use-permission";

const { canAccessHelios, canAccessCenturion } = useFeature();
const { hasPermission } = usePermission();

const {
  data: assets,
  isLoading,
  isError,
} = useGetAssets(canAccessCenturion.value);
const {
  data: bankingAccounts,
  isLoading: loadingBanking,
  isError: bankingError,
} = useListCorporateAccounts(canAccessHelios.value);
const router = useRouter();

const isAccountRequestOpen = ref(false);
const activeAccountRequestId = ref<string | null>(null);

const openAccountRequest = (id: string) => {
  activeAccountRequestId.value = id;
  isAccountRequestOpen.value = true;
};

const closeAccountRequest = () => {
  activeAccountRequestId.value = null;
  isAccountRequestOpen.value = false;
};

const accountList = computed<IAccount[]>(() => {
  let list: IAccount[] = [];
  if (assets.value) {
    const cryptoAssets = assets.value.items.map((it) => ({
      crypto_account: it,
    }));

    list = [...list, ...cryptoAssets];
  }

  if (bankingAccounts.value) {
    const bankingAssets = bankingAccounts.value.map((it) => ({
      banking_account: it,
    }));

    list = [...list, ...bankingAssets];
  }
  return list;
});

const columnTitle = computed(() => {
  if (canAccessCenturion.value && canAccessHelios.value) return "Bank/Network";
  else if (canAccessCenturion.value) return "Network";
  else if (canAccessHelios.value) return "Bank";
  else return "";
});

const columns: TableColumn<
  IAccount & {
    action: string;
  }
>[] = [
  {
    label: "Account",
    selector: () => {},
    dataIndex: "account",
    showOnMobile: false,
  },
  {
    label: "Balance",
    selector: () => {},
    dataIndex: "balance",
    showOnMobile: false,
  },
  {
    label: columnTitle.value,
    selector: () => {},
    dataIndex: "bank",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "status",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "action",
    showOnMobile: false,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "account",
    showOnMobile: true,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "bank",
    showOnMobile: true,
  },
  {
    label: "",
    selector: () => {},
    dataIndex: "action",
    showOnMobile: true,
  },
];

const handleRowClicked = (row: IAccount) => {
  if (row.crypto_account)
    router.push(`/accounts/crypto/${row.crypto_account.id}`);
  else if (row.banking_account && !row.banking_account.in_request_state) {
    router.push(`/accounts/fiat/${row.banking_account.id}`);
  } else if (
    row.banking_account &&
    row.banking_account.in_request_state &&
    row.banking_account.state !== "CANCELLED" &&
    !row.banking_account.has_completed_tasks &&
    hasPermission(LyncPermissions.accounts_write)
  ) {
    openAccountRequest(row.banking_account.account_request_id || "");
  }
};

const handleTableChange = () => {};
</script>
