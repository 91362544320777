<template>
  <router-view />

  <ModalWrapper />
</template>

<script lang="ts" setup>
import { disableIOSTextFieldZoom } from "@/helpers";
import { ModalWrapper } from "./composables/use-modal";
const environment = import.meta.env.VITE_APP_ENVIRONMENT || "production";
if (environment !== "production") {
  const meta = document.createElement("meta");
  meta.name = "robots";
  meta.content = "noindex, nofollow";
  document.head.appendChild(meta);
}

disableIOSTextFieldZoom();
</script>
