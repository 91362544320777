<template>
  <div class="w-full px-5 pt-4 pb-6">
    <h3 class="font-semibold text-sm mb-4">Beneficiary Address</h3>
    <form class="w-full flex flex-col gap-y-4" @submit="onSubmit">
      <app-input
        type="text"
        v-bind="formFields.line_1"
        :error-message="errors.line_1"
        name="line_1"
        label="Address Line 1"
        placeholder="Enter address line 1"
        required
        show-required-label
      />
      <app-input
        type="text"
        v-bind="formFields.line_2"
        :error-message="errors.line_2"
        name="line_2"
        label="Address Line 2"
        placeholder="Enter address line 2"
      />

      <app-input
        type="text"
        v-bind="formFields.city"
        :error-message="errors.city"
        name="city"
        label="City"
        placeholder="Enter City"
        required
        show-required-label
      />

      <app-input
        type="text"
        v-bind="formFields.state"
        :error-message="errors.state"
        name="state"
        label="State"
        placeholder="Enter State"
        required
        show-required-label
      />

      <app-input
        type="text"
        v-bind="formFields.postal_code"
        :error-message="errors.postal_code"
        name="postal_code"
        label="Postal Code"
        placeholder="Enter postal code"
        required
        show-required-label
      />

      <combo-input
        label="Country"
        :data="countries"
        :default-value="values.country"
        :error-message="errors.country"
        required
        show-required-label
        @select="handleSelectedCountry"
      />

      <div class="flex justify-between gap-x-3">
        <app-button
          type="button"
          size="lg"
          variant="outlined"
          @click="switchForm('beneficiary-info')"
          >Back</app-button
        >
        <app-button type="submit" size="lg" variant="primary"
          >Continue</app-button
        >
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { CreateBankingBeneficiary, BankingBeneficiaryView } from "./type";
import { useForm } from "vee-validate";
import { countriesList } from "@/helpers/countries";
import { reactive, computed } from "vue";
import * as yup from "yup";
import { SelectInputData } from "../shared/type";

const props = defineProps<{
  switchForm: (view: BankingBeneficiaryView) => void;
  updateFormData: (data: CreateBankingBeneficiary) => void;
  formData: CreateBankingBeneficiary;
}>();

interface FormFields {
  line_1: string;
  line_2?: string;
  city: string;
  state: string;
  postal_code: string;
  country: string;
}

const countries = computed(() => {
  return countriesList.map((it) => ({
    label: it.name,
    value: it.code,
  }));
});

const { errors, handleSubmit, defineInputBinds, values, setFieldValue } =
  useForm<FormFields>({
    validationSchema: yup.object({
      line_1: yup.string().required().label("Line 1"),
      line_2: yup.string().optional().label("Line 2"),
      city: yup.string().required().label("City"),
      state: yup.string().required().label("State"),
      postal_code: yup.string().required().label("Postal code"),
      country: yup.string().required().label("Country"),
    }),
    initialValues: {
      line_1: props.formData.beneficiary_address?.line_1,
      line_2: props.formData.beneficiary_address?.line_2,
      city: props.formData.beneficiary_address?.city,
      state: props.formData.beneficiary_address?.state,
      postal_code: props.formData.beneficiary_address?.postal_code,
      country: props.formData.beneficiary_address?.country,
    },
  });

const handleSelectedCountry = (val: SelectInputData) => {
  setFieldValue("country", val.value);
};

const onSubmit = handleSubmit((values) => {
  props.updateFormData({
    ...props.formData,
    beneficiary_address: {
      line_1: values.line_1,
      line_2: values.line_2,
      city: values.city,
      state: values.state,
      postal_code: values.postal_code,
      country: values.country,
    },
  });
  if (props.formData.currency === "NGN") {
    props.switchForm("ngn-beneficiary");
    return;
  }
  props.switchForm("bank-address");
});

const formFields = reactive({
  line_1: defineInputBinds("line_1"),
  line_2: defineInputBinds("line_2"),
  city: defineInputBinds("city"),
  state: defineInputBinds("state"),
  postal_code: defineInputBinds("postal_code"),
  country: defineInputBinds("country"),
});
</script>
