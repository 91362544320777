<template>
  <div class="w-full bg-white rounded-[10px] overflow-hidden">
    <div class="px-6 pt-5 pb-4 bg-greyscale-1">
      <h2 class="text-xl font-bold text-primary">Confirm Your Payment</h2>
    </div>
    <div
      class="w-full flex flex-col gap-y-2 px-6 pt-2 pb-3 border-b border-greyscale-3"
    >
      <div class="py-2 border-b border-dashed border-greyscale-3">
        <div class="text-text-secondary text-sm mb-1">You will be charged</div>
        <div class="text-primary font-[800] text-lg">
          {{ quote.billing_amount.currency }}
          {{
            formatAmountToMajor(
              quote.billing_amount.amount,
              quote.billing_amount.currency,
            )
          }}
        </div>
      </div>
      <div class="py-2">
        <div class="text-text-secondary text-sm mb-1">
          Your beneficiary will receive
        </div>
        <div class="text-primary font-[800] text-xl">
          {{ quote.destination_amount.currency }}
          {{
            formatAmountToMajor(
              quote.destination_amount.amount,
              quote.destination_amount.currency,
            )
          }}
        </div>
      </div>
    </div>
    <div class="py-5 px-5 flex flex-col gap-y-5">
      <ul
        v-if="beneficiary.type === 'FIAT'"
        class="w-full flex flex-col gap-y-3"
      >
        <li
          class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
        >
          <div class="text-text-secondary">Beneficiary Name</div>
          <div class="text-primary text-right">
            {{ beneficiary.name || beneficiary.friendly_name }}
          </div>
        </li>
        <li
          v-if="beneficiary.country"
          class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
        >
          <div class="text-text-secondary">Beneficiary Country</div>
          <div class="text-primary text-right">
            {{ countryOf(beneficiary.country).name }}
          </div>
        </li>
        <li
          v-if="beneficiary.details"
          class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
        >
          <div class="text-text-secondary">Account Number</div>
          <div class="text-primary text-right">
            {{
              beneficiary.details.details.accountNumber ||
              beneficiary.details.details.account_number ||
              beneficiary.details.details.iban
            }}
          </div>
        </li>
        <li
          v-if="
            beneficiary.details.details.bankName ||
            beneficiary.details.details.bank_name
          "
          class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
        >
          <div class="text-text-secondary">Bank Name</div>
          <div class="text-primary text-right">
            {{
              beneficiary.details.details.bankName ||
              beneficiary.details.details.bank_name
            }}
          </div>
        </li>
        <li
          v-if="senderReference && senderReference.length"
          class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
        >
          <div class="text-text-secondary">Sender Reference</div>
          <div class="text-primary text-right max-w-[200px]">
            {{ senderReference }}
          </div>
        </li>
        <li
          class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
        >
          <div v-if="narration" class="text-text-secondary">Description</div>
          <div class="text-primary text-right max-w-[200px]">
            {{ narration }}
          </div>
        </li>
      </ul>

      <ul
        v-else-if="beneficiary.type === 'CRYPTO'"
        class="w-full flex flex-col gap-y-3"
      >
        <li
          class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
        >
          <div class="text-text-secondary">Beneficiary</div>
          <div class="text-primary text-right">
            {{ beneficiary.name || beneficiary.friendly_name }}
          </div>
        </li>
        <li
          v-if="beneficiary.details.details"
          class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
        >
          <div class="text-text-secondary">Account Number</div>
          <div class="text-primary text-right">
            {{
              beneficiary.details.details.account_number ||
              beneficiary.details.details.iban
            }}
          </div>
        </li>
        <li
          v-if="beneficiary.details.address"
          class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
        >
          <div class="text-text-secondary">Address</div>
          <div class="text-primary text-right max-w-[200px] break-all">
            {{ beneficiary.details.address }}
          </div>
        </li>
        <li
          v-if="beneficiary.details.blockchain"
          class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
        >
          <div class="text-text-secondary">Blockchain</div>
          <div class="text-primary text-right capitalize">
            {{ beneficiary.details.blockchain }}
          </div>
        </li>

        <li
          class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
        >
          <div v-if="narration" class="text-text-secondary">Description</div>
          <div class="text-primary text-right max-w-[200px]">
            {{ narration }}
          </div>
        </li>
      </ul>

      <div class="flex justify-between gap-x-3 mt-5">
        <app-button variant="outlined" size="lg" @click="closeModal"
          >Cancel</app-button
        >
        <app-button
          :loading="loading"
          :disabled="loading"
          variant="primary"
          size="lg"
          @click="makePayment"
          >Confirm</app-button
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { formatAmountToMajor } from "@/helpers";
import { countryOf } from "@/helpers/countries";
import { BeneficiaryResponse, BankingQuoteResponse } from "@/types";

const props = defineProps<{
  closeModal: () => void;
  makePayment: () => void;
  amount: string;
  currency: string;
  quote: BankingQuoteResponse;
  beneficiary: BeneficiaryResponse;
  loading: boolean;
  narration?: string;
  senderReference?: string;
}>();

console.log(props);
</script>
