<template>
  <section
    class="w-full relative"
    :class="
      business && business.business.kyc_status !== 'APPROVED'
        ? 'after:absolute after:top-0 after:left-0 after:w-full after:h-full after:bg-white/40'
        : ''
    "
  >
    <approval-requests-banner
      v-if="
        approvalRequests?.items.length &&
        hasPermission(LyncPermissions.approval_requests_read)
      "
      :refetch="refetch"
      :requests="approvalRequests?.items"
    />
    <div
      class="flex justify-center md:justify-between flex-wrap items-start lg:flex-row mb-8 gap-4"
    >
      <div>
        <div class="text-lg text-text-secondary mb-2 text-center lg:text-left">
          Total Assets
        </div>
        <div
          class="text-4xl lg:text:[44px] font-[800] bg-clip-text bg-brown-gradient whitespace-nowrap text-transparent"
        >
          <template v-if="canAccessCenturion">
            <skeleton-loader
              v-if="loadingUsdBalanceValue || refetchingUsdBalance"
              :count="1"
            />
            <span v-else-if="totalUsdBalance">
              ${{ formatAmountToMajor(totalUsdBalance.available, "USD") }}
            </span>
            <span v-else-if="usdBalanceError" class="text-xs">
              Error fetching balance
            </span>
          </template>
          <template v-else> 0.00 </template>
        </div>
      </div>
      <div
        class="flex flex-wrap gap-x-3 gap-y-4 mb-5 lg:mb-0 justify-center lg:justify-start"
      >
        <app-button
          v-if="
            hasPermission(LyncPermissions.accounts_write) &&
            (canAccessHelios || canAccessCenturion)
          "
          variant="outlined"
          size="md"
          @click="openCreateAsset"
        >
          <span class="w-[15px] h-[15px] inline-block">
            <add-icon />
          </span>
          <span>Create an account</span>
        </app-button>

        <Menu>
          <MenuButton
            class="flex h-[35px] items-center rounded-[5px] font-medium bg-primary px-3 py-2 gap-x-3 text-white w-fit md:h-10 md:px-5 text-sm"
          >
            <div class="flex items-center gap-x-2">
              <money-icon />
              <span>Send Money</span>
            </div>
            <white-arrow-down-icon class="h-[18px] fill-white" />
          </MenuButton>
          <MenuItems
            class="absolute right-8 md:right-0 left-none mt-24 md:mt-12 w-fit origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none z-[20]"
          >
            <div class="w-full flex flex-col">
              <MenuItem
                v-if="
                  canAccessHelios &&
                  hasPermission(LyncPermissions.transactions_write)
                "
              >
                <router-link
                  to="/payments/send-money"
                  class="flex w-[200px] items-center gap-x-2 px-3 py-3 text-primary text-sm text-left border-b border-greyscale-7 hover:bg-greyscale-2"
                >
                  <airplane-icon class="fill-primary" />
                  Send domestic wire
                </router-link>
              </MenuItem>
              <MenuItem
                v-if="
                  canAccessHelios &&
                  hasPermission(LyncPermissions.transactions_write)
                "
              >
                <router-link
                  to="/payments/send-money"
                  class="flex w-[200px] items-center gap-x-2 px-3 py-3 text-primary text-sm text-left border-b border-greyscale-7 hover:bg-greyscale-2"
                >
                  <airplane-icon class="fill-primary" />
                  Send international wire
                </router-link>
              </MenuItem>

              <MenuItem
                v-if="
                  canAccessCenturion &&
                  hasPermission(LyncPermissions.transactions_write)
                "
              >
                <router-link
                  to="/payments/send-stablecoins"
                  class="flex w-[200px] items-center gap-x-2 px-3 py-3 text-primary text-sm text-left border-b border-greyscale-7 hover:bg-greyscale-2"
                >
                  <coins-icon /> Send stablecoins
                </router-link>
              </MenuItem>
            </div>
          </MenuItems>
        </Menu>
      </div>
    </div>
    <div
      v-if="business && business.business.kyc_status === 'APPROVED'"
      class="w-full"
    >
      <div
        v-if="isLoading || isRefetching || bankAccountsLoading"
        class="w-full md:w-1/2"
      >
        <skeleton-loader :count="4" />
      </div>
      <div v-else-if="accountList.length" class="w-full">
        <div
          v-if="!showGlider"
          class="w-full flex flex-wrap md:flex-nowrap gap-x-5 gap-y-3"
        >
          <balance-card
            v-for="account in accountList"
            :key="String(account)"
            :account="account"
          />
        </div>
        <div v-else-if="showGlider" ref="glideContainer" class="glide">
          <div class="glide__track" data-glide-el="track">
            <ul class="glide__slides">
              <li
                v-for="account in accountList"
                :key="String(account)"
                class="glide__slide"
              >
                <balance-card :account="account" />
              </li>
            </ul>
          </div>
          <div
            v-show="showGlider"
            class="flex justify-center items-center gap-x-2 mt-3"
            data-glide-el="controls"
          >
            <button
              v-show="showGlider"
              class="glide__arrow glide__arrow--left"
              data-glide-dir="<"
            >
              <circle-arrow-left-icon />
            </button>
            <button
              v-show="showGlider"
              class="glide__arrow glide__arrow--right"
              data-glide-dir=">"
            >
              <circle-arrow-right-icon />
            </button>
          </div>
        </div>
      </div>

      <error-component v-else-if="isError || isHeliosError" />
    </div>
    <div v-else class="w-full flex overflow-x-auto flex-nowrap gap-x-5 gap-y-3">
      <dummy-balance-card
        id="USD"
        variant="accent-green"
        :asset-icon="UsdFlag"
        asset-name="USD"
        currency="USD"
        balance="0.0"
        dollar-balance="0.0"
      />
      <div class="min-w-max w-full hidden md:block">
        <dummy-balance-card
          id="EURO"
          variant="accent-blue"
          :asset-icon="EurFlag"
          asset-name="EUR"
          currency="EUR"
          balance="0.0"
          dollar-balance="0.0"
        />
      </div>
    </div>
  </section>

  <app-modal
    :is-open="showCreateAsset"
    :handle-close="closeCreateAsset"
    size="md"
  >
    <create-asset :close-modal="closeCreateAsset" />
  </app-modal>
</template>

<script setup lang="ts">
import Glide from "@glidejs/glide";
import { UsdFlag, EurFlag } from "../icons";
import { formatAmountToMajor } from "@/helpers";
import { useGetAssets } from "@/data-access/assets";
import { useLoggedInBusiness } from "@/composables/states";
import { useFeature } from "@/composables/use-feature";
import { usePermission } from "@/composables/use-permission";
import { onMounted, watch, onUnmounted, computed, ref } from "vue";
import { IAccount } from "@/types";
import { Menu, MenuItems, MenuItem, MenuButton } from "@headlessui/vue";
import {
  useGetTotalUsdBalance,
  useListCorporateAccounts,
} from "@/data-access/accounts";
import { LyncPermissions } from "@/security/permissions";
import { useWindowSize } from "@vueuse/core";
import { useListApprovalRequests } from "@/data-access/transactions";

const { business } = useLoggedInBusiness();
const { canAccessCenturion, canAccessHelios } = useFeature();
const { hasPermission } = usePermission();

const glideContainer = ref<HTMLElement | null>(null);
const glide = ref<Glide | null>(null);
const { width } = useWindowSize();

const slidePerView = () => {
  if (width.value >= 1280) {
    return 4;
  } else if (width.value >= 1024) {
    return 3;
  } else if (width.value >= 640) {
    return 2;
  } else {
    return 1;
  }
};

const showGlider = computed(() => {
  return accountList.value.length > slidePerView();
});

const { data: approvalRequests, refetch } = useListApprovalRequests(
  {
    page: ref(1),
    limit: ref(100),
    filters: {
      state: ref(["PENDING"]),
    },
  },
  hasPermission(LyncPermissions.approval_requests_read),
);

const {
  data: assets,
  isLoading,
  isRefetching,
  isError,
} = useGetAssets(canAccessCenturion.value);

const {
  data: totalUsdBalance,
  isLoading: loadingUsdBalanceValue,
  isRefetching: refetchingUsdBalance,
  isError: usdBalanceError,
} = useGetTotalUsdBalance();

const {
  data: bankAccounts,
  isLoading: bankAccountsLoading,
  isError: isHeliosError,
} = useListCorporateAccounts(canAccessHelios.value);

const accountList = computed<IAccount[]>(() => {
  let list: IAccount[] = [];
  if (assets.value) {
    const cryptoAssets = assets.value.items.map((it) => ({
      crypto_account: it,
    }));

    list = [...list, ...cryptoAssets];
  }

  if (bankAccounts.value) {
    const bankingAssets = bankAccounts.value
      .filter((ac) => !ac.in_request_state)
      .map((it) => ({
        banking_account: it,
      }));

    list = [...list, ...bankingAssets];
  }
  return list;
});

const showCreateAsset = ref(false);

const openCreateAsset = () => {
  showCreateAsset.value = true;
};

const closeCreateAsset = () => {
  showCreateAsset.value = false;
};

watch(assets, (val) => {
  if (val && val.items && glide.value) {
    glide.value.update();
  }
});

watch(glideContainer, (val) => {
  if (val && !glide.value) {
    glide.value = new Glide(val, {
      type: "slider",
      perView: 5,
      gap: 20,
      breakpoints: {
        1800: {
          perView: 5,
        },
        1500: {
          perView: 4,
        },
        1280: {
          perView: 4,
        },
        1200: {
          perView: 3,
        },
        1024: {
          perView: 2,
        },
        640: {
          perView: 1,
        },
      },
    });
    glide.value.mount();
  }
});

onMounted(() => {
  if (glide.value) {
    glide.value.mount();
  }
});

onUnmounted(() => {
  if (glide.value) {
    glide.value.destroy();
    glide.value = null;
  }
});
</script>
