<template>
  <div class="relative bg-white overflow-hidden w-full h-full overflow-y-auto">
    <div
      class="w-full bg-greyscale-1 h-28 px-5 py-5 flex justify-center items-center"
    >
      <div
        v-if="transaction.type === 'DEPOSIT'"
        class="font-[800] text-4xl text-primary"
      >
        +
        {{ transaction.destination_currency }}
        {{
          formatAmountToMajor(
            transaction.destination_amount,
            transaction.currency,
          )
        }}
      </div>
      <div v-else class="font-[800] text-4xl text-primary">
        -
        {{ transaction.source_currency }}
        {{
          formatAmountToMajor(
            transaction.source_amount,
            transaction.source_currency,
          )
        }}
      </div>
    </div>
    <div class="w-full flex flex-col justify-between py-5">
      <div class="w-full px-5">
        <ul class="mb-8 flex flex-col gap-y-3">
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">ID</div>
            <div class="text-primary text-right">
              {{ transaction.reference }}
            </div>
          </li>
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Date</div>
            <div class="text-primary text-right">
              {{ formatDateTime(transaction.created_date) }}
            </div>
          </li>

          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Type</div>
            <div v-if="isTransactionOtc" class="flex gap-x-1 justify-end">
              <debit-icon />
              <span class="capitalize text-[#B96B6B]">Otc</span>
            </div>
            <div v-else class="flex justify-end gap-x-1">
              <credit-icon v-if="transaction.type === 'DEPOSIT'" />
              <debit-icon v-else-if="transaction.type === 'TRANSFER'" />
              <exchange-icon v-else-if="transaction.type === 'EXCHANGE'" />
              <span
                class="capitalize"
                :class="
                  transaction.type === 'DEPOSIT'
                    ? 'text-[#297FB0]'
                    : transaction.type === 'TRANSFER'
                      ? 'text-[#B96B6B]'
                      : 'text-processing/70'
                "
                >{{ transaction.type?.toLowerCase() }}</span
              >
            </div>
          </li>
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Status</div>
            <div class="text-primary text-right">
              <transaction-status :status="transaction.state" />
            </div>
          </li>

          <li
            v-if="transaction.sender_reference?.length"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Sender Reference</div>
            <div class="text-primary text-right">
              {{ transaction.sender_reference }}
            </div>
          </li>

          <li
            v-if="transaction.failure_reason"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Reason</div>
            <div class="text-primary text-right break-all">
              {{ transaction.failure_reason }}
            </div>
          </li>
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Description</div>
            <div class="text-primary text-right break-all">
              {{ transaction.description }}
            </div>
          </li>
          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Amount Sent</div>
            <div class="text-primary text-right">
              {{ transaction.source_currency }}
              {{
                formatAmountToMajor(
                  transaction.sending_amount,
                  transaction.source_currency,
                )
              }}
            </div>
          </li>

          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Total Fees</div>
            <div class="text-primary text-right">
              {{ transaction.source_currency }}
              {{
                formatAmountToMajor(
                  transaction.total_fee_amount,
                  transaction.total_fee_currency,
                )
              }}
            </div>
          </li>

          <li
            v-if="transaction.fx_rate"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Fx Rate</div>
            <div class="text-primary text-right">
              <rate-description
                :rate="transaction.fx_rate"
                :sending-currency="transaction.source_currency"
              />
            </div>
          </li>

          <li
            v-if="transaction.billing_amount"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Total Amount</div>
            <div class="text-primary text-right">
              {{ transaction.billing_currency }}
              {{
                formatAmountToMajor(
                  transaction.billing_amount,
                  transaction.billing_currency,
                )
              }}
            </div>
          </li>

          <li
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Amount Received</div>
            <div class="text-primary text-right">
              {{ transaction.destination_currency }}
              {{
                formatAmountToMajor(
                  transaction.destination_amount,
                  transaction.destination_currency,
                )
              }}
            </div>
          </li>

          <li
            v-if="transaction.payment_initiator"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Initiated By</div>
            <div class="text-primary text-right">
              {{ transaction.payment_initiator.email }}
            </div>
          </li>
          <li
            v-if="transaction.payment_approver"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Approved By</div>
            <div class="text-primary text-right">
              {{ transaction.payment_approver.email }}
            </div>
          </li>
          <li
            v-if="transaction.payment_approver?.date"
            class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
          >
            <div class="text-text-secondary">Approval Date</div>
            <div class="text-primary text-right">
              {{ formatDateTime(transaction.payment_approver.date) }}
            </div>
          </li>
        </ul>
        <div v-if="transaction.type === 'DEPOSIT'">
          <h3 class="text-sm font-medium mb-3">SENDER</h3>

          <ul
            v-if="transaction.source.counterparty"
            class="w-full flex flex-col gap-y-5"
          >
            <li
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Type</div>
              <div class="text-primary text-right">
                {{ convertToWords(transaction.source.counterparty.type) }}
              </div>
            </li>

            <li
              v-if="transaction.source.name"
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Name</div>
              <div class="text-primary text-right">
                {{ transaction.source.name }}
              </div>
            </li>

            <div
              v-for="it in Object.keys(
                transaction.source.counterparty.details,
              ).filter((it) => !['bank_code', 'nip_code'].includes(it))"
              :key="it"
              class="w-full flex flex-col gap-y-5"
            >
              <template
                v-if="
                  transaction.source.counterparty.details[it] &&
                  typeof transaction.source.counterparty.details[it] ===
                    'object'
                "
              >
                <li
                  v-for="item in Object.keys(
                    transaction.source.counterparty.details[it],
                  ).filter((it) => it === 'country')"
                  :key="item"
                  class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
                >
                  <div class="text-text-secondary">
                    {{ convertToWords(it) }} {{ convertToWords(item) }}
                  </div>
                  <div class="text-primary text-right">
                    {{
                      transaction.source.counterparty.details[it][item] || "N/A"
                    }}
                  </div>
                </li>
              </template>

              <li
                v-else
                class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
              >
                <div class="text-text-secondary">{{ convertToWords(it) }}</div>
                <div class="text-primary text-right">
                  {{ transaction.source.counterparty.details[it] || "N/A" }}
                </div>
              </li>
            </div>
          </ul>

          <ul
            v-else-if="isTxnSourceAccount && accountDetails"
            class="w-full flex flex-col gap-y-3 capitalize"
          >
            <li
              class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Name</div>
              <div class="text-primary text-right">
                {{ accountDetails.name }}
              </div>
            </li>
            <li
              class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Type</div>
              <div class="text-primary text-right">
                {{ accountDetails.currency }}
                ({{
                  accountDetails.deposit_addresses[0].type === "FED_WIRE"
                    ? "Wire"
                    : accountDetails.deposit_addresses[0].type
                }})
              </div>
            </li>

            <li
              v-if="accountDetails.deposit_addresses[0].data.account_number"
              class="w-full flex justify-between gap-x-10 items-start text-sm font-medium"
            >
              <div class="text-text-secondary">Account Number</div>
              <div
                class="text-primary capitalize flex justify-end max-w-[500px] gap-x-1 gap-y-2 items-center text-right break-all"
              >
                {{ accountDetails.deposit_addresses[0].data.account_number }}
                <button
                  class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
                  @click="
                    copyAddress(
                      accountDetails.deposit_addresses[0].data.account_number,
                    )
                  "
                >
                  <copy-icon />
                </button>
              </div>
            </li>

            <li
              v-if="accountDetails.deposit_addresses[0].data.iban"
              class="w-full flex justify-between gap-x-10 items-start text-sm font-medium"
            >
              <div class="text-text-secondary">IBAN</div>
              <div
                class="text-primary capitalize flex justify-end max-w-[500px] gap-x-1 gap-y-2 items-center text-right break-all"
              >
                {{ accountDetails.deposit_addresses[0].data.iban }}
                <button
                  class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
                  @click="
                    copyAddress(accountDetails.deposit_addresses[0].data.iban)
                  "
                >
                  <copy-icon />
                </button>
              </div>
            </li>

            <li
              v-if="accountDetails.deposit_addresses[0].data.routing_number"
              class="w-full flex justify-between gap-x-10 items-start text-sm font-medium"
            >
              <div class="text-text-secondary">Routing Number</div>
              <div
                class="text-primary capitalize flex justify-end max-w-[500px] gap-x-1 gap-y-2 items-center text-right break-all"
              >
                {{ accountDetails.deposit_addresses[0].data.routing_number }}
                <button
                  class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
                  @click="
                    copyAddress(
                      accountDetails.deposit_addresses[0].data.routing_number,
                    )
                  "
                >
                  <copy-icon />
                </button>
              </div>
            </li>

            <li
              v-if="accountDetails.deposit_addresses[0].data.sort_code"
              class="w-full flex justify-between gap-x-10 items-start text-sm font-medium"
            >
              <div class="text-text-secondary">Sort Code</div>
              <div
                class="text-primary capitalize flex justify-end max-w-[500px] gap-x-1 gap-y-2 items-center text-right break-all"
              >
                {{ accountDetails.deposit_addresses[0].data.sort_code }}
                <button
                  class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
                  @click="
                    copyAddress(
                      accountDetails.deposit_addresses[0].data.sort_code,
                    )
                  "
                >
                  <copy-icon />
                </button>
              </div>
            </li>

            <li
              v-if="accountDetails.deposit_addresses[0].data.account_name"
              class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Account Name</div>
              <div class="text-primary text-right">
                {{ accountDetails.deposit_addresses[0].data.account_name }}
              </div>
            </li>

            <li
              v-if="accountDetails.deposit_addresses[0].data.bank_name"
              class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Bank Name</div>
              <div class="text-primary text-right">
                {{ accountDetails.deposit_addresses[0].data.bank_name }}
              </div>
            </li>
          </ul>

          <div v-else>Couldn't get sender details</div>
        </div>

        <div v-else-if="transaction.type !== 'DEPOSIT'">
          <h3 class="text-sm font-medium mb-3 mt-8">
            BENEFICIARY
            <router-link
              v-if="
                txnCounterparty &&
                (txnCounterparty.account_number || txnCounterparty.iban)
              "
              :to="`/beneficiaries/${transaction.destination.id}`"
              class="text-sm text-text-primary/70 underline"
              >(View)</router-link
            >
          </h3>

          <ul
            v-if="transaction.destination.counterparty"
            class="w-full flex flex-col gap-y-5"
          >
            <li
              v-if="transaction.destination.name"
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Name</div>
              <div class="text-primary text-right">
                {{ convertToWords(transaction.destination.name) }}
              </div>
            </li>
            <li
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Type</div>
              <div class="text-primary text-right">
                {{ convertToWords(transaction.destination.counterparty.type) }}
              </div>
            </li>

            <li
              v-if="txnCounterparty.bank_name"
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Bank Name</div>
              <div class="text-primary text-right">
                {{ txnCounterparty.bank_name }}
              </div>
            </li>
            <li
              v-if="txnCounterparty.account_number"
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Account number</div>
              <div class="text-primary text-right">
                {{ txnCounterparty.account_number }}
              </div>
            </li>
            <li
              v-if="txnCounterparty.account_name"
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Account name</div>
              <div class="text-primary text-right">
                {{ txnCounterparty.account_name }}
              </div>
            </li>
            <li
              v-if="txnCounterparty.swift_bic"
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Swift/Bic</div>
              <div class="text-primary text-right">
                {{ txnCounterparty.swift_bic }}
              </div>
            </li>
            <li
              v-if="txnCounterparty.bank_address?.country"
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Bank Country</div>
              <div class="text-primary text-right">
                {{ countryOf(txnCounterparty.bank_address.country).name }}
              </div>
            </li>

            <li
              v-if="txnCounterparty.intermediary_bank"
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Intermediary Bank Name</div>
              <div class="text-primary text-right">
                {{ txnCounterparty.intermediary_bank.name }}
              </div>
            </li>

            <li
              v-if="txnCounterparty.intermediary_bank"
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Intermediary Bank Swift/Bic</div>
              <div class="text-primary text-right">
                {{ txnCounterparty.intermediary_bank.swift_bic }}
              </div>
            </li>

            <li
              v-if="txnCounterparty.intermediary_bank?.address?.country"
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Intermediary Bank Country</div>
              <div class="text-primary text-right">
                {{
                  countryOf(txnCounterparty.intermediary_bank.address.country)
                    .name
                }}
              </div>
            </li>
          </ul>

          <ul v-else-if="asset" class="w-full flex flex-col gap-y-3 capitalize">
            <li
              class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Name</div>
              <div class="text-primary text-right">
                {{ asset.name }}
              </div>
            </li>

            <li
              class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Asset</div>
              <div class="text-primary text-right">
                {{ asset.asset.currency }}
                ({{ asset.asset.blockchain }})
              </div>
            </li>

            <li
              class="w-full flex justify-between gap-x-10 items-start text-sm font-medium"
            >
              <div class="text-text-secondary">Address</div>
              <div
                class="text-primary capitalize flex flex-col justify-start max-w-[500px] gap-x-3 gap-y-2 items-end text-right break-all"
              >
                {{ asset.deposit_address.address }}
                <button
                  v-if="asset.deposit_address.address"
                  class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
                  @click="copyAddress(asset.deposit_address.address)"
                >
                  <copy-icon /> Copy Address
                </button>
              </div>
            </li>
          </ul>

          <ul
            v-else-if="cryptoCounterparty"
            class="w-full flex flex-col gap-y-3 capitalize"
          >
            <li
              class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Name</div>
              <div class="text-primary text-right">
                {{ cryptoCounterparty.name }}
              </div>
            </li>

            <li
              class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Asset</div>
              <div class="text-primary text-right">
                {{ cryptoCounterparty.currency }}
                ({{ cryptoCounterparty.details.blockchain }})
              </div>
            </li>

            <li
              class="w-full flex justify-between gap-x-10 items-start text-sm font-medium"
            >
              <div class="text-text-secondary">Address</div>
              <div
                class="text-primary capitalize flex flex-col justify-start max-w-[500px] gap-x-3 gap-y-2 items-end text-right break-all"
              >
                {{ cryptoCounterparty.details.address }}
                <button
                  v-if="cryptoCounterparty.details.address"
                  class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
                  @click="copyAddress(cryptoCounterparty.details.address)"
                >
                  <copy-icon /> Copy Address
                </button>
              </div>
            </li>
          </ul>

          <ul
            v-else-if="isTxnDestinationAccount && accountDetails"
            class="w-full flex flex-col gap-y-3 capitalize"
          >
            <li
              class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Name</div>
              <div class="text-primary text-right">
                {{ accountDetails.name }}
              </div>
            </li>
            <li
              class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Type</div>
              <div class="text-primary text-right">
                {{ accountDetails.currency }}
                ({{
                  accountDetails.deposit_addresses[0].type === "FED_WIRE"
                    ? "Wire"
                    : accountDetails.deposit_addresses[0].type
                }})
              </div>
            </li>

            <li
              v-if="accountDetails.deposit_addresses[0].data.account_number"
              class="w-full flex justify-between gap-x-10 items-start text-sm font-medium"
            >
              <div class="text-text-secondary">Account Number</div>
              <div
                class="text-primary capitalize flex justify-end max-w-[500px] gap-x-1 gap-y-2 items-center text-right break-all"
              >
                {{ accountDetails.deposit_addresses[0].data.account_number }}
                <button
                  class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
                  @click="
                    copyAddress(
                      accountDetails.deposit_addresses[0].data.account_number,
                    )
                  "
                >
                  <copy-icon />
                </button>
              </div>
            </li>

            <li
              v-if="accountDetails.deposit_addresses[0].data.iban"
              class="w-full flex justify-between gap-x-10 items-start text-sm font-medium"
            >
              <div class="text-text-secondary">IBAN</div>
              <div
                class="text-primary capitalize flex justify-end max-w-[500px] gap-x-1 gap-y-2 items-center text-right break-all"
              >
                {{ accountDetails.deposit_addresses[0].data.iban }}
                <button
                  class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
                  @click="
                    copyAddress(accountDetails.deposit_addresses[0].data.iban)
                  "
                >
                  <copy-icon />
                </button>
              </div>
            </li>

            <li
              v-if="accountDetails.deposit_addresses[0].data.routing_number"
              class="w-full flex justify-between gap-x-10 items-start text-sm font-medium"
            >
              <div class="text-text-secondary">Routing Number</div>
              <div
                class="text-primary capitalize flex justify-end max-w-[500px] gap-x-1 gap-y-2 items-center text-right break-all"
              >
                {{ accountDetails.deposit_addresses[0].data.routing_number }}
                <button
                  class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
                  @click="
                    copyAddress(
                      accountDetails.deposit_addresses[0].data.routing_number,
                    )
                  "
                >
                  <copy-icon />
                </button>
              </div>
            </li>

            <li
              v-if="accountDetails.deposit_addresses[0].data.sort_code"
              class="w-full flex justify-between gap-x-10 items-start text-sm font-medium"
            >
              <div class="text-text-secondary">Sort Code</div>
              <div
                class="text-primary capitalize flex justify-end max-w-[500px] gap-x-1 gap-y-2 items-center text-right break-all"
              >
                {{ accountDetails.deposit_addresses[0].data.sort_code }}
                <button
                  class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
                  @click="
                    copyAddress(
                      accountDetails.deposit_addresses[0].data.sort_code,
                    )
                  "
                >
                  <copy-icon />
                </button>
              </div>
            </li>

            <li
              v-if="accountDetails.deposit_addresses[0].data.account_name"
              class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Account Name</div>
              <div class="text-primary text-right">
                {{ accountDetails.deposit_addresses[0].data.account_name }}
              </div>
            </li>

            <li
              v-if="accountDetails.deposit_addresses[0].data.bank_name"
              class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary">Bank Name</div>
              <div class="text-primary text-right">
                {{ accountDetails.deposit_addresses[0].data.bank_name }}
              </div>
            </li>
          </ul>

          <div v-else>Couldn't get beneficiary details</div>
        </div>

        <div
          v-if="transactionDocumentation && transactionDocumentation.length"
          class="w-full mt-5"
        >
          <h3 class="text-sm font-medium mb-3">DOCUMENTS</h3>
          <ul class="w-full flex flex-col gap-y-5">
            <li
              v-for="doc in transactionDocumentation"
              :key="doc.id"
              class="w-full justify-between flex gap-x-10 items-center text-sm font-medium"
            >
              <div class="text-text-secondary capitalize truncate break-all">
                {{ doc.description }}
              </div>
              <div
                v-if="doc.file_info"
                class="text-primary capitalize flex flex-col justify-start max-w-[500px] gap-x-3 gap-y-2 items-end text-right break-all"
              >
                <button
                  class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
                  :disabled="downloadingDoc === doc.id"
                  @click="() => downloadPaymentDocument(doc.id)"
                >
                  <document-icon />
                  {{
                    downloadingDoc === doc.id ? "Downloading..." : "Download"
                  }}
                </button>
              </div>
              <div
                v-else-if="doc.value"
                class="text-primary capitalize flex flex-col justify-start max-w-[500px] gap-x-3 gap-y-2 items-end text-right break-all"
              >
                {{ doc.value }}
                <button
                  class="text-xs bg-greyscale-2 px-3 py-2 rounded-[20px] flex items-center gap-x-2 cursor-pointer"
                  @click="copyValue(doc.value)"
                >
                  <copy-icon /> Copy
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div
        v-if="
          transaction.state === 'PENDING_APPROVAL' &&
          hasPermission(LyncPermissions.transactions_approve)
        "
        class="w-full px-5 mt-8"
      >
        <div class="flex justify-between gap-x-2">
          <app-button
            size="lg"
            variant="outlined"
            :loading="rejecting"
            :disabled="rejecting"
            @click="rejectRequest"
          >
            Reject payment
          </app-button>
          <app-button
            size="lg"
            variant="primary"
            :loading="approving"
            :disabled="approving"
            @click="approveRequest"
          >
            Approve payment
          </app-button>
        </div>
      </div>

      <div class="flex mt-8 justify-between items-center gap-x-4 px-5">
        <router-link to="/transactions" class="w-full">
          <app-button variant="outlined" size="lg"
            >Go to transactions</app-button
          >
        </router-link>
        <app-button
          v-if="['COMPLETED', 'PROCESSING'].includes(transaction.state)"
          :disabled="downloading"
          :loading="downloading"
          variant="primary"
          size="lg"
          @click="
            () =>
              downloadFile(
                bankingUrl(`/payments/${props.transaction.id}/receipt`),
              )
          "
          >Download receipt</app-button
        >
      </div>
    </div>
    <button
      class="border w-10 h-10 rounded-full flex items-center justify-center absolute right-5 top-5"
      @click="closeModal"
    >
      <close-icon />
    </button>
  </div>
</template>

<script lang="ts" setup>
import {
  formatDateTime,
  convertToWords,
  formatAmountToMajor,
  copyToClipboard,
} from "@/helpers";
import { countryOf } from "@/helpers/countries";
import apiClient, { bankingUrl } from "@/helpers/apiClient";
import { useWriteResource } from "@/composables/use-resource";
import { errorMessage } from "@/helpers/error";
import { BankingTransactionResponse, QueryKeys } from "@/types";
import { ref, computed } from "vue";
import { usePermission } from "@/composables/use-permission";
import { useAppToast } from "@/composables";
import { LyncPermissions } from "@/security/permissions";
import { useQueryClient } from "@tanstack/vue-query";
import { useGetTransactionDocumentation } from "@/data-access/transactions";
import { useGetAsset } from "@/data-access/assets";
import { useGetBeneficiary } from "@/data-access/beneficiary";
import { useGetBankAccount } from "@/data-access/accounts";
import { useDownloader } from "@/composables/use-download";

const props = defineProps<{
  transaction: BankingTransactionResponse;
  refetch: () => void;
  closeModal: () => void;
}>();

const downloading = ref(false);
const downloadingDoc = ref("");
const { hasPermission } = usePermission();

const { data: transactionDocumentation } = useGetTransactionDocumentation(
  props.transaction.id,
);

const isAsset = computed(() => {
  return props.transaction.destination?.type === "Asset";
});

const isTxnDestinationAccount = computed(() => {
  return props.transaction.destination?.type === "Account";
});

const isTxnSourceAccount = computed(() => {
  return props.transaction.source?.type === "Account";
});

const isCryptoCounterparty = computed(() => {
  return (
    props.transaction.destination?.type === "CounterParty" &&
    !props.transaction.destination?.counterparty
  );
});

const { data: asset } = useGetAsset(
  props.transaction.destination.id,
  isAsset.value,
);

const { data: accountDetails } = useGetBankAccount(
  isTxnDestinationAccount.value
    ? props.transaction.destination.id
    : props.transaction.source.id,
  isTxnDestinationAccount.value || isTxnSourceAccount.value,
);

const { data: cryptoCounterparty } = useGetBeneficiary(
  props.transaction.destination.id,
  isCryptoCounterparty.value,
);

const toast = useAppToast();
const queryClient = useQueryClient();
const downloader = useDownloader();

const copyAddress = (address: string) => {
  copyToClipboard(address);
  toast.success("Copied to clipboard", {
    position: "top-right",
  });
};

const copyValue = (val: string) => {
  copyToClipboard(val);
  toast.success("Copied to clipboard", {
    position: "top-right",
  });
};

const isTransactionOtc = computed(() => {
  return props.transaction.tags
    ? props.transaction.tags.includes("OTC")
    : false;
});

const txnCounterparty = computed(
  () => props.transaction.destination?.counterparty?.details || {},
);

const downloadFile = async (url: string) => {
  try {
    downloading.value = true;
    const res = await apiClient.get(url, {
      responseType: "blob",
    });
    await downloader.fromBlob(res.data, res.headers["content-disposition"]);
  } catch (e) {
    toast.error(errorMessage(e), {
      position: "top-right",
    });
  } finally {
    downloading.value = false;
  }
};

const downloadPaymentDocument = async (docId: string) => {
  downloadingDoc.value = docId;
  await downloadFile(bankingUrl(`/payments/docs/${docId}/download`));
  downloadingDoc.value = "";
};

const { submitting: approving, execute: approveRequest } = useWriteResource(
  `/approval-requests/${props.transaction.id}/approve`,
  "patch",
  {
    successTitle: `Request Approval successful`,
    onSuccess: () => {
      props.refetch();
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.APPROVAL_REQUESTS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.BANKING_TRANSACTIONS],
      });
      props.closeModal();
    },
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { submitting: rejecting, execute: rejectRequest } = useWriteResource(
  `/approval-requests/${props.transaction.id}/reject`,
  "patch",
  {
    successTitle: `Request Rejection successful`,
    onSuccess: () => {
      props.refetch();
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.APPROVAL_REQUESTS],
      });
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.BANKING_TRANSACTIONS],
      });
      props.closeModal();
    },
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);
</script>
