<template>
  <Combobox v-model="selectedBeneficiary">
    <div class="relative">
      <span
        class="text-sm text-primary font-medium w-fit min-w-fit inline-block mb-[9px]"
        >Beneficiary</span
      >
      <div class="relative w-full overflow-hidden text-sm text-primary">
        <ComboboxInput
          class="w-full border border-[#E2E2E2] py-2 pl-4 pr-10 text-sm text-primary-primary rounded-[5px] focus:ring-0 focus:outline-primary placeholder:text-sm"
          :display-value="(it: any) => getDisplayValue(it)"
          placeholder="Enter beneficiary name"
          @change="query = $event.target.value"
        />
        <ComboboxButton
          class="absolute inset-y-0 right-0 flex items-center pr-2"
        >
          <arrow-down-icon />
        </ComboboxButton>
      </div>
      <div
        v-if="isError"
        class="relative cursor-default select-none px-4 py-2 text-sm text-text-primary"
      >
        Error fetching beneficiaries - Contact support
      </div>
      <ComboboxOptions
        v-if="beneficiaries"
        class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-primary/5 focus:outline-none sm:text-sm z-10"
      >
        <div
          v-if="isFetching"
          class="relative cursor-default select-none px-4 py-2 text-text-primary"
        >
          <SpinnerIcon />
        </div>
        <div
          v-else-if="filteredBeneficiaries.length === 0 && query.length >= 4"
          class="relative cursor-default select-none px-4 py-2 text-text-primary"
        >
          Nothing found.
        </div>
        <div
          v-else-if="!beneficiaries.items.length"
          class="relative cursor-default select-none px-4 py-2 text-text-primary"
        >
          You have no {{ currency || "" }} beneficiaries yet
        </div>
        <ComboboxOption
          v-for="beneficiary in filteredBeneficiaries"
          :key="beneficiary.id"
          v-slot="{ selected, active }"
          as="template"
          :value="beneficiary"
        >
          <li
            class="relative cursor-pointer select-none py-2 pl-3 pr-3 md:pl-10 md:pr-4 border-b border-[#E8EAE2] last-of-type:border-b-0"
            :class="{
              'bg-greyscale-2 text-primary': active,
              'text-text-primary': !active,
            }"
          >
            <div>
              <div class="mb-1 font-medium">
                {{ beneficiary.name || beneficiary.friendly_name }}
              </div>
              <div
                v-if="beneficiary.type === 'FIAT'"
                class="text-primary/50 truncate"
              >
                <span>
                  {{ beneficiary.currency }}
                </span>
                <span v-if="beneficiary.details?.details?.bank_name">
                  | {{ beneficiary.details.details.bank_name }}
                </span>
                <span>
                  |
                  {{
                    beneficiary.details.type === "FED_WIRE"
                      ? "WIRE"
                      : snakeCaseToWords(beneficiary.details.type)
                  }}
                  Account -
                  {{
                    beneficiary.details.details.account_number ||
                    beneficiary.details.details.iban
                  }}
                </span>
              </div>
              <div
                v-else-if="beneficiary.type === 'CRYPTO'"
                class="text-primary/50 truncate"
              >
                <span>{{ beneficiary.currency }}</span>
                <span>
                  | {{ beneficiary.details.blockchain }} -
                  {{ beneficiary.details.address }}
                </span>
              </div>
            </div>

            <span
              v-if="selected"
              class="absolute inset-y-0 left-0 flex items-center pl-3"
            >
              <check-icon aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script lang="ts" setup>
import { useGetBeneficiaries } from "@/data-access/beneficiary";
import { BeneficiaryResponse } from "@/types";
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
} from "@headlessui/vue";
import { debounce } from "lodash";
import { watch, ref } from "vue";
import { snakeCaseToWords } from "@/helpers";

const props = defineProps<{
  currency?: string;
  network?: string;
  defaultBeneficiary?: BeneficiaryResponse;
}>();

const emit = defineEmits<{
  (e: "select", value: BeneficiaryResponse): void;
}>();

const selectedCurrency = ref(props.currency);
const selectedNetwork = ref(props.network);
const query = ref("");
const searchQuery = ref("");

const {
  data: beneficiaries,
  isFetching,
  isError,
} = useGetBeneficiaries({
  page: ref(0),
  limit: ref(20),
  filters: {
    keyword: searchQuery,
    currency: selectedCurrency,
    network: selectedNetwork,
  },
});

const filteredBeneficiaries = ref<BeneficiaryResponse[]>(
  beneficiaries?.value?.items || [],
);

watch(beneficiaries, (it) => {
  filteredBeneficiaries.value = it?.items || [];
});

const selectedBeneficiary = ref<BeneficiaryResponse | null>(
  props.defaultBeneficiary || null,
);

watch(selectedBeneficiary, (it) => {
  if (it) {
    emit("select", it);
  }
});

watch(query, (val) => {
  filteredBeneficiaries.value =
    beneficiaries?.value?.items.filter(
      (it) => it.name?.toLowerCase().includes(val.toLowerCase()),
    ) || [];
});

watch(
  query,
  debounce((val) => {
    if (val.length >= 4) {
      searchQuery.value = val;
    }
  }, 50),
);

watch(
  () => props.currency,
  (it) => {
    selectedCurrency.value = it;
    if (selectedBeneficiary.value?.currency !== it) {
      selectedBeneficiary.value = null;
    }
  },
);

watch(
  () => props.network,
  (it) => {
    selectedNetwork.value = it;
    if (
      selectedBeneficiary.value?.details &&
      selectedBeneficiary.value?.details?.blockchain !== it
    ) {
      selectedBeneficiary.value = null;
    }
  },
);

const getDisplayValue = (it: BeneficiaryResponse) => {
  return it?.name || it?.friendly_name || "";
};
</script>
