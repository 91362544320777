<template>
  <form
    class="w-full max-w-[450px] bg-white px-0 xl:px-10 text-primary"
    @submit="onSubmit"
  >
    <header class="w-full mb-8">
      <h2 class="text-xl font-[800] mb-2">Change password</h2>
    </header>
    <div class="w-full flex flex-col gap-y-[26px] mb-8">
      <app-input
        label="Current password"
        type="password"
        name="password"
        v-bind="formFields.password"
        :error-message="errors.password"
        placeholder="Enter your new password"
      />
      <app-input
        label="New password"
        type="password"
        name="newPassword"
        v-bind="formFields.newPassword"
        :error-message="errors.newPassword"
        placeholder="Enter your new password"
      />
      <app-input
        label="Confirm password"
        type="password"
        name="confirmNewPassword"
        v-bind="formFields.confirmNewPassword"
        :error-message="errors.confirmNewPassword"
        placeholder="Confirm your new password"
      />
    </div>
    <app-button
      :loading="submitting"
      :disabled="submitting"
      type="submit"
      size="lg"
      variant="primary"
    >
      Change password
    </app-button>
  </form>
</template>

<script setup lang="ts">
import { useWriteResource } from "@/composables/use-resource";
import { errorMessage } from "@/helpers/error";
import { useForm } from "vee-validate";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { useAppToast } from "@/composables";
import * as yup from "yup";

interface FormFields {
  password: string;
  newPassword: string;
  confirmNewPassword: string;
}

const toast = useAppToast();
const router = useRouter();

const { submitting, execute: handleChangePassword } = useWriteResource(
  "/settings/change-password",
  "post",
  {
    successTitle: "Password change Successful",
    onError: (err) => {
      toast.error(errorMessage(err), {
        position: "top-right",
      });
    },
  },
);

const { errors, handleSubmit, defineInputBinds } = useForm<FormFields>({
  validationSchema: yup.object({
    password: yup.string().min(8).required().label("Password"),
    newPassword: yup
      .string()
      .min(8)
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()_\-+={}[\]|\\:;"'<>,.\\/?])[A-Za-z\d~`!@#$%^&*()_\-+={}[\]|\\:;"'<>,.\\/?]{8,}$/,
        "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character",
      )
      .label("New password"),
    confirmNewPassword: yup
      .string()
      .oneOf(
        [yup.ref("newPassword"), undefined],
        "Password must match new password",
      )
      .required()
      .label("Confirm new password"),
  }),
});

const onSubmit = handleSubmit(async (values) => {
  await handleChangePassword({
    body: {
      password: values.password,
      new_password: values.newPassword,
    },
  });
  router.push("/");
});

const formFields = reactive({
  password: defineInputBinds("password"),
  newPassword: defineInputBinds("newPassword"),
  confirmNewPassword: defineInputBinds("confirmNewPassword"),
});
</script>
