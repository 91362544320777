import { RequestOptions } from "@/types";
import axios, { AxiosError, AxiosResponse } from "axios";
import { Ref } from "vue";

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  withCredentials: true,
});

const refreshClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  withCredentials: true,
});

export const withoutAuthClient = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

let isRefreshing = false;
let refreshSubscribers: Array<() => void> = [];

const subscribeToRefresh = (callback: () => void) => {
  refreshSubscribers.push(callback);
};

const onRefreshSuccess = () => {
  refreshSubscribers.forEach((callback) => callback());
  refreshSubscribers = [];
};

const publicPaths = [
  "/login",
  "/create-account",
  "/forgot-password",
  "/email-verification",
  "/reset-password",
  "/invitation",
];

const isOnPublicPath = () => {
  return publicPaths.some((path) => window.location.pathname.includes(path));
};

const _logout = () => {
  if (!isOnPublicPath()) {
    window.location.href = "/login";
  }
};

const refreshToken = async (error: AxiosError): Promise<AxiosResponse> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const originalRequest = error.config as any;
  if (!originalRequest || originalRequest._retry) {
    return Promise.reject(error);
  }

  if (isRefreshing) {
    return new Promise((resolve) => {
      subscribeToRefresh(() => {
        resolve(apiClient(originalRequest));
      });
    });
  }

  originalRequest._retry = true;
  isRefreshing = true;

  try {
    await refreshClient.post("/onboarding/refresh-token", null, {
      withCredentials: true,
    });

    onRefreshSuccess();
    isRefreshing = false;
    return apiClient(originalRequest);
  } catch (refreshError) {
    isRefreshing = false;
    refreshSubscribers = [];
    _logout();
    return Promise.reject(refreshError);
  }
};

apiClient.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if (error.response?.status === 401 && !isOnPublicPath()) {
      return refreshToken(error);
    }
    return Promise.reject(error);
  },
);

export const cryptoUrl = (url: string) => `crypto/v1/` + url;
export const bankingUrl = (url: string) => `banking/v1/` + url;

export const useRequestOptions = (options: RequestOptions) => {
  const params = new URLSearchParams();
  params.append("page", options.page.value.toString());
  params.append("limit", options.limit.value.toString());
  if (options.filters) {
    const filters = options.filters;
    Object.keys(filters).forEach((key) => {
      if (filters[key].value && Array.isArray(filters[key].value)) {
        filters[key].value.forEach((val: string) => {
          params.append(`${key}[]`, val);
        });
      } else if (filters[key].value) {
        params.append(key, filters[key].value);
      }
    });
  }

  return params.toString();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useRequestFilters = (filters?: Record<string, Ref<any>>) => {
  const params = new URLSearchParams();
  if (filters) {
    Object.keys(filters).forEach((key) => {
      if (filters[key].value && Array.isArray(filters[key].value)) {
        filters[key].value.forEach((val: string) => {
          params.append(`${key}[]`, val);
        });
      } else if (filters[key].value) {
        params.append(key, filters[key].value);
      }
    });
  }

  return params.toString();
};

export default apiClient;
