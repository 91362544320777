export const supportedCountries = [
  {
    name: "United States",
    code: "US",
    iso3: "USA",
    currency: "USD",
    dial_code: "+1",
    emoji: "🇺🇸",
  },
  {
    name: "Nigeria",
    code: "NG",
    iso3: "NGA",
    currency: "NGN",
    dial_code: "+234",
    emoji: "🇳🇬",
  },
  {
    name: "Austria",
    code: "AT",
    iso3: "AUT",
    currency: "EUR",
    dial_code: "+43",
    emoji: "🇦🇹",
  },
  {
    name: "Belgium",
    code: "BE",
    iso3: "BEL",
    currency: "EUR",
    dial_code: "+32",
    emoji: "🇧🇪",
  },
  {
    name: "Bulgaria",
    code: "BG",
    iso3: "BGR",
    currency: "BGN",
    dial_code: "+359",
    emoji: "🇧🇬",
  },
  {
    name: "Canada",
    code: "CA",
    iso3: "CAN",
    currency: "CAD",
    dial_code: "+1",
    emoji: "🇨🇦",
  },
  {
    name: "Croatia",
    code: "HR",
    iso3: "HRV",
    currency: "HRK",
    dial_code: "+385",
    emoji: "🇭🇷",
  },
  {
    name: "Cyprus",
    code: "CY",
    iso3: "CYP",
    currency: "EUR",
    dial_code: "+357",
    emoji: "🇨🇾",
  },
  {
    name: "Czech Republic",
    code: "CZ",
    iso3: "CZE",
    currency: "CZK",
    dial_code: "+420",
    emoji: "🇨🇿",
  },
  {
    name: "Denmark",
    code: "DK",
    iso3: "DNK",
    currency: "DKK",
    dial_code: "+45",
    emoji: "🇩🇰",
  },
  {
    name: "Estonia",
    code: "EE",
    iso3: "EST",
    currency: "EUR",
    dial_code: "+372",
    emoji: "🇪🇪",
  },
  {
    name: "Finland",
    code: "FI",
    iso3: "FIN",
    currency: "EUR",
    dial_code: "+358",
    emoji: "🇫🇮",
  },
  {
    name: "France",
    code: "FR",
    iso3: "FRA",
    currency: "EUR",
    dial_code: "+33",
    emoji: "🇫🇷",
  },
  {
    name: "Germany",
    code: "DE",
    iso3: "DEU",
    currency: "EUR",
    dial_code: "+49",
    emoji: "🇩🇪",
  },
  {
    name: "Greece",
    code: "GR",
    iso3: "GRC",
    currency: "EUR",
    dial_code: "+30",
    emoji: "🇬🇷",
  },
  {
    name: "Hungary",
    code: "HU",
    iso3: "HUN",
    currency: "HUF",
    dial_code: "+36",
    emoji: "🇭🇺",
  },
  {
    name: "Ireland",
    code: "IE",
    iso3: "IRL",
    currency: "EUR",
    dial_code: "+353",
    emoji: "🇮🇪",
  },
  {
    name: "Italy",
    code: "IT",
    iso3: "ITA",
    currency: "EUR",
    dial_code: "+39",
    emoji: "🇮🇹",
  },
  {
    name: "Latvia",
    code: "LV",
    iso3: "LVA",
    currency: "EUR",
    dial_code: "+371",
    emoji: "🇱🇻",
  },
  {
    name: "Lithuania",
    code: "LT",
    iso3: "LTU",
    currency: "EUR",
    dial_code: "+370",
    emoji: "🇱🇹",
  },
  {
    name: "Luxembourg",
    code: "LU",
    iso3: "LUX",
    currency: "EUR",
    dial_code: "+352",
    emoji: "🇱🇺",
  },
  {
    name: "Malta",
    code: "MT",
    iso3: "MLT",
    currency: "EUR",
    dial_code: "+356",
    emoji: "🇲🇹",
  },
  {
    name: "Netherlands",
    code: "NL",
    iso3: "NLD",
    currency: "EUR",
    dial_code: "+31",
    emoji: "🇳🇱",
  },
  {
    name: "Poland",
    code: "PL",
    iso3: "POL",
    currency: "PLN",
    dial_code: "+48",
    emoji: "🇵🇱",
  },
  {
    name: "Portugal",
    code: "PT",
    iso3: "PRT",
    currency: "EUR",
    dial_code: "+351",
    emoji: "🇵🇹",
  },
  {
    name: "Romania",
    code: "RO",
    iso3: "ROU",
    currency: "RON",
    dial_code: "+40",
    emoji: "🇷🇴",
  },
  {
    name: "Slovakia",
    code: "SK",
    iso3: "SVK",
    currency: "EUR",
    dial_code: "+421",
    emoji: "🇸🇰",
  },
  {
    name: "Slovenia",
    code: "SI",
    iso3: "SVN",
    currency: "EUR",
    dial_code: "+386",
    emoji: "🇸🇮",
  },
  {
    name: "Spain",
    code: "ES",
    iso3: "ESP",
    currency: "EUR",
    dial_code: "+34",
    emoji: "🇪🇸",
  },
  {
    name: "Sweden",
    code: "SE",
    iso3: "SWE",
    currency: "SEK",
    dial_code: "+46",
    emoji: "🇸🇪",
  },
  {
    name: "United Kingdom",
    code: "GB",
    iso3: "GBR",
    currency: "GBP",
    dial_code: "+44",
    emoji: "🇬🇧",
  },
];
