<template>
  <div class="w-full px-5 py-5">
    <div
      class="w-full flex flex-col gap-y-2 py-5 bg-greyscale-1 px-5 rounded-[5px]"
    >
      <div class="py-2 border-b border-dashed border-greyscale-3">
        <div class="text-text-secondary text-sm mb-1">You are billed</div>
        <div class="text-primary font-[800] text-lg">
          {{ payment.billing_amount }}
        </div>
      </div>
      <div class="py-2">
        <div class="text-text-secondary text-sm mb-1">You receive</div>
        <div class="text-primary font-[800] text-xl">
          {{ payment.destination_amount }}
        </div>
      </div>

      <ul class="w-full flex flex-col gap-y-3">
        <li
          v-if="payment.exchange_rate"
          class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
        >
          <div class="text-text-secondary">Exchange rate</div>
          <div class="text-primary text-right">
            <rate-description
              :rate="payment.exchange_rate"
              :sending-currency="payment.billing_currency"
            />
          </div>
        </li>

        <li
          class="w-full flex justify-between gap-x-10 items-center text-sm font-medium"
        >
          <div class="text-text-secondary">Transaction fees</div>
          <div class="text-primary text-right">
            {{ payment.total_fees }}
          </div>
        </li>
      </ul>
    </div>

    <div class="flex flex-col gap-y-3 mt-5">
      <slot name="confirm-button">
        <app-button
          :loading="loading"
          :disabled="loading"
          variant="primary"
          size="lg"
          @click="handleMakePayment"
          >Confirm</app-button
        >
      </slot>
      <app-button variant="outlined" size="lg" @click="cancelPayment"
        >Cancel</app-button
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CurrentRateV2 } from "@/types";
import { ref } from "vue";

interface PaymentConfirmation {
  exchange_rate?: CurrentRateV2;
  billing_currency: string;
  destination_amount: string;
  billing_amount: string;
  total_fees: string;
}

const props = defineProps<{
  cancelPayment: () => void;
  makePayment: () => Promise<void>;
  payment: PaymentConfirmation;
}>();

const loading = ref(false);

const handleMakePayment = async () => {
  loading.value = true;
  await props.makePayment();
  loading.value = false;
};
</script>
